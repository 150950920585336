import * as types from '../mutation-types'
import axios from '../../common/axios.js'
import Utils from '../../common/utils.js'
import _ from 'lodash'
import moment from 'moment'
import utils from '../../common/utils.js'


// initial state
const state = {
	company: { id: null, prev: null, next: null },
	companyComplementsLoad: 0,
	company_hover: null,
	company_edition_id: null,
	company_inexdata: null,
	modale_edition_matieres_company: false,
	edition_matiere: null,
	edition_matiere_uniq: null,
	edition_save_matiere: false,
	dechets_api: [],
	loadiNexCompanyCancel: null
}

// getters
const getters = {
	getFieldHistoryStr: (state, getters, rootState) => (field, child_id, text) => {
		let key = field + '_' + (child_id ? child_id : 0)
		if(state.company.complement && state.company.complement.historiques && state.company.complement.historiques[key]){

			let dateObj = new Date(state.company.complement.historiques[key]['created']);
			let momentObj = moment(dateObj);

			return text + ' ' + state.company.complement.historiques[key]['email'] + ' ' + momentObj.format('DD/MM/YYYY')
		}
		
		return false
	}
}

// actions
const actions = {
	setCompany({ commit, rootState }, company) {
		if (document.fullscreen) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
			}
		}

		if (company !== null) {
			var actu = false;
			rootState.entreprises.raw.some(e => {
				if (actu == true) {
					company.next = e;
					return true;
				}
				if (e.id == company.id) {
					actu = true;
				} else {
					company.prev = e;
				}
			})
			console.time('-- UI_SET_COMPANY_MODAL');
			commit('UI_SET_COMPANY_MODAL', company);
			console.timeEnd('-- UI_SET_COMPANY_MODAL');
		} else {
			commit('UI_SET_COMPANY_MODAL', {
				id: null,
				prev: null,
				next: null
			});
		}
	},
	setCompanyComplementsLoad({ commit, state, rootState }) {
		commit('UI_SET_COMPANY_COMPLEMENTS_LOAD', _.now());
	},
	set_company_hover({ commit, state, rootState }, company_id) {
		if (state.company_hover != company_id) {
			commit('UI_SET_COMPANY_HOVER', company_id);
		}
	},
	entreprise_modifie_champ({ commit, state, rootState, dispatch }, params) {

		let entreprise = _.cloneDeep(state.company);
		entreprise[params.champ] = params.valeur;
		entreprise.complement[params.champ] = params.valeur;
		entreprise.force = true
		dispatch('setCompany', entreprise)

		let convert = {
			nbr_salaries: 	'employes',
			tel1: 			'phoneNumber',
			email: 			'mail',
			email_verifie: 	'mail_checked',
			billing_data: 	'turnover',
			enseigne: 		'commercial_name',
		}

		let values = {}
		let field = convert[params.champ] ? convert[params.champ] : params.champ
		values[field] = params.valeur
		axios.put('api=inex/companies/'+params.id, values)

	},
	entreprise_modifie_capacite({ commit, state, rootState, dispatch }, params) {

		let entreprise = _.cloneDeep(state.company);

		let modified = false
		entreprise.complement.capacites.forEach(function(capacite, i){
			if(capacite.id == params.capacite_id){
				entreprise.complement.capacites[i].val = params.valeur;
				modified = true
			}
		})
		if(!modified){
			entreprise.complement.capacites.push({
				id: params.capacite_id, 
				val: params.valeur
			})
		}

		dispatch('setCompany', entreprise)
		
		axios.post('/plugin_projet/plugin_projet_iannuaire/entreprise_modifie_capacite/'+params.id, params)
			.then(function(response) {

			}).catch(function(error) {

			})
	},
	entreprise_modifie_contact({ commit, state, rootState, dispatch }, params) {

		//AS--> Met à jour le contact dans l'interface
		let company = _.cloneDeep(state.company)
		let contact = { ...company.complement.contacts[params.key], ...params.contact }
		company.complement.contacts[params.key] = contact
		dispatch('setCompany', company)

		//AS--> Met à jour le contact dans la DB
		axios.post('/plugin_projet/plugin_projet_iannuaire/entreprise_modifie_contact_lie', contact)
			.then(function(response) {
				//AS--> Remet à jour tous les contacts d'après la réponse reçue
				if(typeof response.data == 'object'){
					company.complement.contacts = response.data
					dispatch('setCompany', company)
				}
			}).catch(function(error) {

			})
		
	},
	entreprise_supprime_contact({ commit, state, rootState, dispatch }, contact_id) {

		//AS--> Met à jour le contact dans l'interface
		let company = _.cloneDeep(state.company)
		company.complement.contacts.forEach(function(contact, i){
		  if(contact.id == contact_id) company.complement.contacts.splice(i,1);
		})
		dispatch('setCompany', company)

		//AS--> Met à jour le contact dans la DB
		axios.get('/plugin_projet/plugin_projet_iannuaire/entreprise_supprime_contact_lie/'+contact_id)
			.then(function(response) {
				//AS--> Remet à jour tous les contacts d'après la réponse reçue
				if(typeof response.data == 'object'){
					company.complement.contacts = response.data
					dispatch('setCompany', company)
				}
			}).catch(function(error) {
			})
		
	},
	entreprise_modifie_logo({ commit, state, rootState, dispatch }, params) {
			
		//AS--> Mise à jour dans l'objet company
		let company = _.cloneDeep(state.company)
		company.complement.image = params.image
		company.force = true
		commit('UI_SET_COMPANY_MODAL', company);

		//AS--> Si on a une image
		if(typeof params.image == 'object' && params.image.type.substring(0, 6) == "image/"){

			let url_image = 'api=inex/companies/'+state.company.id+'/image'
			let data_image = new FormData();
			data_image.append('image', params.image, params.image.name);
			let headers = {
			  headers: {
			    'accept': 'application/json',
			    'Content-Type': `multipart/form-data; boundary=${data_image._boundary}`,
			  }
			}

			//AS--> On récupère l'image actuelle pour vérifier s'il y en avait déjà une et si il faut la supprimer
			let actuelle = axios.get(url_image)

			//AS--> Il y avait une image, donc on la supprime
			.then(function(image_get_response){
				axios.delete(url_image).then(function(){
					axios.post(url_image, data_image, headers)
				})
			}).catch(function(){
				axios.post(url_image, data_image, headers)
			})

		}

	},
	entreprise_modifie_matiere({ commit, state, rootState, dispatch }, params) {
		
		commit('UI_SET_COMPANY_SAVE_MATIERE', true);

		let data   = state.edition_matiere
		data.draft = params && params.brouillon ? true : false

		//AS--> Données à sauvegarder
		let data_to_save   = _.cloneDeep(data)

		//AS--> Supprime les champs qui ne sont pas utils à la sauvegarde
		if(data_to_save.image || data_to_save.image == null) delete data_to_save.image
		if(data_to_save.actif) delete data_to_save.actif
		if(data_to_save.material_name) delete data_to_save.material_name
		if(data_to_save.new || data_to_save.new == false) delete data_to_save.new

		let url    = 'api=inex/companies/'+state.company.id+'/products/'
		let method = 'put'
		if(data.new === true){
			method = 'post'
			data.new = undefined
		}else{
			url += data.product_id+'/'+data.material_id+'/'
		}

		axios({method, url, data:data_to_save})
			.then(function(response) {
				commit('UI_SET_COMPANY_SAVE_MATIERE', false);

				//AS--> Si on a un picto_personnalise
				if(typeof data.image == 'object' && data.image.type.substring(0, 6) == "image/"){

					let url_image = url+'image'
					let data_image = new FormData();
					data_image.append('image', data.image, data.image.name);
					let headers = {
					  headers: {
					    'accept': 'application/json',
					    'Content-Type': `multipart/form-data; boundary=${data_image._boundary}`,
					  }
					}

					//AS--> On récupère l'image actuelle pour vérifier s'il y en avait déjà une et si il faut la supprimer
					let actuelle = axios.get(url_image)

					//AS--> Il y avait une image, donc on la supprime
					.then(function(image_get_response){
						axios.delete(url_image).then(function(){
							axios.post(url_image, data_image, headers)
						})
					}).catch(function(){
						axios.post(url_image, data_image, headers)
					})

				}

			}).catch(function(error) {
				commit('UI_SET_COMPANY_SAVE_MATIERE', false);

			})

		//AS--> Merge cette matière éditée à la liste des matières
		let dechets = _.cloneDeep(state.dechets_api)
		let merged  = false
		dechets.forEach(function(dechet, k){
			if(dechet.product_id == data.product_id && dechet.material_id == data.material_id){
				dechets[k] = data
				merged = true
			}
		})
		if(!merged){
			dechets.push(data)
		}

		commit('UI_SET_COMPANY_DECHETS_API', dechets);

	},
	async loadiNexCompany({ commit, state, rootState, dispatch, getters }) {
		return new Promise((resolve, reject) => {

			if(state.company_inexdata?.general_info?.identifiant && state.company_inexdata.general_info.identifiant == state.company.id) resolve(state.company);

			//AS--> Annule la précédente requête XHR si elle est encore en cours d'exécution
			// if (this.cancelSource) this.cancelSource.cancel('Start new search, stop active search');
			if (state.loadiNexCompanyCancel) state.loadiNexCompanyCancel.cancel('Start new search, stop active search');

			//AS--> Stock un nouveau token d'exécution Axios
			commit('UI_SET_COMPANY_LOADINEXCOMPANYCANCEL', axios.CancelToken.source());

			commit('UI_ADD_LOADING_LAYER');

			//AS--> Réalise la requête sur iNex data
			axios.get('api=inex/companies/'+state.company.id+'?is_for_front=true', { cancelToken: state.loadiNexCompanyCancel.token })
				.then(function(response) {
					commit('UI_REMOVE_LOADING_LAYER');
					if (response.data != 'CONNEXION') {

						commit('UI_SET_COMPANY_INEX_DATA', _.cloneDeep(response.data))

						console.log(response.data)

						let _company              = _.cloneDeep(state.company)
						_company.complement       = _.cloneDeep(response.data.general_info)
						_company.company_inexdata = _.cloneDeep(response.data)

						//AS--> Cherche s'il y a un tableau des déchets à setter
						if(response.data?.complements?.length){
							let dechets = utils.methods.getCompagnyDechetsListFromComplements(response.data.complements)
							if(dechets){
								commit('UI_SET_COMPANY_DECHETS_API', dechets);
							}
						}

						_company.force = true
						dispatch('setCompany', _company)
						resolve(_company);
					}
					reject(error);
				}).catch(function(error) {
					commit('UI_REMOVE_LOADING_LAYER');
					reject(error);
				})

    	})
	},
	entreprise_supprime_matiere({ commit, state }, params){

		//AS--> Suppression chez inex
		let url    = 'api=inex/companies/'+state.company.id+'/products/'+params.matiere.product_id+'/'+params.matiere.material_id+'/'
		let method = 'delete'
		axios({method, url})
		.then(function(response){

			//AS--> Si la suppression a bien fonctionné chez iNex, on supprime du référentiel chargé
			let dechets = _.cloneDeep(state.dechets_api)
			_.remove(dechets, dechet => dechet.product_id == params.matiere.product_id && dechet.material_id == params.matiere.material_id)

			if(state.dechets_api.length != dechets.length){
				commit('UI_SET_COMPANY_DECHETS_API', dechets);
			}

		})


	}
}

// mutations
const mutations = {
	[types.UI_SET_COMPANY_MODAL](state, params) {
		console.time('UI_SET_COMPANY_MODAL');
		if(state.company.id != params.id || params.force){
			state.company = params;

			delete params.force

			//AS--> Mise à jour de state.company_inexdata.general_info
			if(params.complement){
				let company_inexdata = _.cloneDeep(state.company_inexdata)
				company_inexdata.general_info = _.cloneDeep(params.complement)
				state.company_inexdata = company_inexdata
			}
		}
		console.timeEnd('UI_SET_COMPANY_MODAL');
	},
	[types.UI_SET_COMPANY_EDITION_ID](state, id) {
		console.time('UI_SET_COMPANY_EDITION_ID');
		state.company_edition_id = id;
		console.timeEnd('UI_SET_COMPANY_EDITION_ID');
	},
	[types.UI_SET_COMPANY_COMPLEMENTS_LOAD](state, timestamp) {
		console.time('UI_SET_COMPANY_COMPLEMENTS_LOAD');
		state.companyComplementsLoad = timestamp;
		console.timeEnd('UI_SET_COMPANY_COMPLEMENTS_LOAD');
	},
	[types.UI_SET_COMPANY_HOVER](state, company_id) {
		console.time('UI_SET_COMPANY_HOVER');
		state.company_hover = company_id;
		console.timeEnd('UI_SET_COMPANY_HOVER');
	},
	[types.UI_SET_COMPANY_EDITION_MATIERE](state, matiere) {
		console.time('UI_SET_COMPANY_EDITION_MATIERE');
		state.edition_matiere = matiere;
		state.edition_matiere_uniq = _.now();
		console.timeEnd('UI_SET_COMPANY_EDITION_MATIERE');
		console.log(_.cloneDeep(matiere))
	},
	[types.UI_SET_COMPANY_SAVE_MATIERE](state, etat) {
		console.time('UI_SET_COMPANY_SAVE_MATIERE');
		state.edition_save_matiere = etat;
		console.timeEnd('UI_SET_COMPANY_SAVE_MATIERE');
	},
	[types.UI_SET_COMPANY_INEX_DATA](state, data) {
		console.time('UI_SET_COMPANY_INEX_DATA');
		state.company_inexdata = data;
		console.timeEnd('UI_SET_COMPANY_INEX_DATA');
	},
	[types.UI_SET_COMPANY_EDIT_MATIERES_MODALE](state, affichage_bool) {
		console.time('UI_SET_COMPANY_EDIT_MATIERES_MODALE');
		state.modale_edition_matieres_company = affichage_bool;
		console.timeEnd('UI_SET_COMPANY_EDIT_MATIERES_MODALE');
	},
	[types.UI_SET_COMPANY_DECHETS_API](state, dechets_api) {
		console.time('UI_SET_COMPANY_DECHETS_API');
		state.dechets_api = dechets_api;
		console.timeEnd('UI_SET_COMPANY_DECHETS_API');
	},
	[types.UI_SET_COMPANY_LOADINEXCOMPANYCANCEL](state, cancel) {
		console.time('UI_SET_COMPANY_LOADINEXCOMPANYCANCEL');
		state.loadiNexCompanyCancel = cancel;
		console.timeEnd('UI_SET_COMPANY_LOADINEXCOMPANYCANCEL');
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}