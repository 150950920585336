import _ from 'lodash'
import axios from './axios.js'

export default {
	props: ['params'],
	data(){
		return {
			image: null,
			imagePreview: null,
			showPreview: false
		}
	},
	methods: {
		langue(text){
			let self = this

			//AS--> Application de la regex sur le texte pour vérifier s'il peut être découpé
			const regexp = /(?:^|\n|\r|\|)([a-z]{3})\:([^|\n]+)/gi
			const array = [...text.matchAll(regexp)];

			//AS--> S'il n'y a pas de résultats, on renvoie le texte telquel
			if(!array.length) return text

			//AS--> Boucle sur les traductions pour trouver la bonne
			let traduction = null;
			array.forEach(function(txt_lng){
				if(txt_lng[1] == self.$store.state.ui.langue) traduction = txt_lng[2]
			})
			if(!traduction) traduction = txt_lng[0][2]

			//AS--> Renvoie la bonne traduction, nettoyée
			return traduction.trim()
		},
		postInexFieldData(field, value, _url, _identifiant){

			//AS--> Détéremination de l'identifiant
			let identifiant = _identifiant ? _identifiant : this.$store.state.company.company.id
			if(!identifiant) return

			//AS--> Détermination de l'url
			let url = _url ? _url : 'api=inex/companies/{id_national}'
			url = url.replace('{id_national}', identifiant)

			//AS--> Envoi de la requête
			let data = {}
			data[field] = value
			axios.put(url, data)
		},
		postInexFieldsData(fields_data, _url, _identifiant){

			//AS--> Détéremination de l'identifiant
			let identifiant = _identifiant ? _identifiant : this.$store.state.company.company.id
			if(!identifiant) return

			//AS--> Détermination de l'url
			let url = _url ? _url : 'api=inex/companies/{id_national}'
			url = url.replace('{id_national}', identifiant)

			//AS--> Envoi de la requête
			axios.put(url, fields_data)
		},
		returnImage(image){
			console.log('returnImage');
			if(image){
				console.log('A');
				if(typeof image == 'object' && image.type && image.type.substring(0,6) == 'image/'){
					console.log('B');
					return this.getBase64Image(image)
				}
			}
		},
		getBase64Image(img) {
		    // Create an empty canvas element
		    var canvas = document.createElement("canvas");
		    canvas.width = img.width;
		    canvas.height = img.height;

		    // Copy the image contents to the canvas
		    var ctx = canvas.getContext("2d");
		    ctx.drawImage(img, 0, 0);

		    // Get the data-URL formatted image
		    // Firefox supports PNG and JPEG. You could check img.src to guess the
		    // original format, but be aware the using "image/jpg" will re-encode the image.
		    return canvas.toDataURL(img.type);
		},
		getCompagnyDechetsListFromComplements(company_complements){
			let self    = this
			let dechets = null
			if(Array.isArray(company_complements) && company_complements.length){
				company_complements.forEach(function(complement){
					if(complement.format == 'products'){
						dechets = complement.list
					}else if(complement.content?.length){
						let result = self.getCompagnyDechetsListFromComplements(complement.content)
						if(result) dechets = result
					}
				})
			}
			return dechets
		},
	},
	computed: {
		
	}
}