import * as types from '../mutation-types'
import axios from '../../common/axios.js'
import _ from 'lodash'

// initial state
const state = {
	address: null,
	layers: [],
	preview: null,
	getLayersByIds_cancelSearch: null
}

const compare_zones = function(zones_1, zones_2){
	let zones_1_sort = _.clone(zones_1)
	let zones_2_sort = _.clone(zones_2)
	zones_1_sort.sort()
	zones_2_sort.sort()
	return _.isEqual(zones_1_sort, zones_2_sort)
}

// getters
const getters = {
	// hasFilters_parcelles: (state, getters, rootState) => (filtre) => {
	// 	let filtres = _.cloneDeep(state.menu_parcelles_filtres)
	// 	delete filtres.groupe
	// 	return Object.keys(filtres).length > 0;
	// }
	searches_getLayersFeatures: (state, getters, rootState) => {
		//AS--> Si il n'y a pas de layers, on retourne un tableau vide
		if(!state.layers.length) return []

		let features = _.map(state.layers, function(layer) { 
			layer.type = "Feature"
			return layer
		});
		return {
			"type":"FeatureCollection", 
			"features":features,
			"properties":{
				"style":{
					"strokeColor": "#07CE6F",
					"fillColor": "#07CE6F",
					"fillOpacity": 0.25,
					"strokeWeight": 1,
					"strokeOpacity": 0.5,
					"zIndex": 1,
					"display": true
				},
			}
		};
	},
}

// actions
const actions = {
	// setFeatureOver({ commit, state, rootState }, feature) {
	// 	commit('PARCELLE_FEATURE_OVER', feature);
	// },
	searches_setAddress({ commit, state, rootState }, address) {
		commit('SEARCHES_ADDRESS', address);
		commit('SEARCHES_LAYERS', []);
	},
	searches_addLayer({ commit, state, rootState, dispatch }, layer) {
		commit('SEARCHES_ADD_LAYER', layer);
		dispatch('searches_updateFilter');
	},
	searches_removeLayer({ commit, state, rootState, dispatch }, layer_id) {
		commit('SEARCHES_REMOVE_LAYER', layer_id);
		dispatch('searches_updateFilter');
	},
	searches_updateFilter({ commit, dispatch, state, rootState }) {

		//AS--> S'il y a des layers
		if(state.layers.length){

			//AS--> Enregistre sur Filters la géométrie de toutes les features sélectionnées
			let zones_selected_polygons = [];
			state.layers.forEach(function(feature){
				if(feature.geometry.type == 'Polygon'){
					zones_selected_polygons.push(feature.geometry.coordinates);
				}
				if(feature.geometry.type == 'MultiPolygon'){
					zones_selected_polygons = [].concat(zones_selected_polygons, feature.geometry.coordinates);
				}
			})
			commit('FILTERS_SET_ZONES_SELECTED_POLYGONS', zones_selected_polygons)

			//AS--> On récupère les Ids de layers
			let layers_ids = _.map(state.layers, function(layer) { return layer.properties.id; });

			let zoneFilter = {
				area_filter: 		true,
				map_filter_mode :  	'zones',
				zones_filter: 		{
					layer: '',
					zones: layers_ids
				}
			}

			if(
				zoneFilter['area_filter'] != rootState.filters.filters.area_filter 
				|| zoneFilter['map_filter_mode'] != rootState.filters.filters.map_filter_mode 
				|| !compare_zones(zoneFilter['zones_filter']['zones'], rootState.filters.filters.zones_filter.zones)
				){
				dispatch('setZoneFilters', zoneFilter);
			}


		}else{
			let zoneFilter = {
				area_filter: 		false,
				map_filter_mode :  	'aucun',
				zones_filter: 		{
					layer: '',
					zones: []
				}
			}
			dispatch('setZoneFilters', zoneFilter);
		}

	},
	clear_layers({ commit, dispatch, state, rootState }) {
		commit('SEARCHES_LAYERS', []);
	},
	reset_layers({ commit, dispatch, state, rootState }) {
		dispatch('clear_layers');
		dispatch('searches_updateFilter');
	},
	getLayersByIds({ commit, dispatch, state, rootState }, layers_ids) {

		if (state.getLayersByIds_cancelSearch) state.getLayersByIds_cancelSearch.cancel('Start new search, stop active search');
		commit('SEARCHES_SET_GETLAYERSBYIDS_CANCELSEARCH', axios.CancelToken.source());

		console.log('====== getLayersByIds ======', layers_ids)
		//AS--> On récupère les layers
		axios.get('api=inex/search/all?layers_id='+layers_ids.join(','), { cancelToken: state.getLayersByIds_cancelSearch.token })
		.then((response) => {
			commit('SEARCHES_LAYERS', response.data);
			dispatch('searches_updateFilter');
		})
		.catch((error) => {
			console.log('error', error)
		})
	},
}

// mutations
const mutations = {
	[types.SEARCHES_ADDRESS](state, address) {
		state.address = address;
	},
	[types.SEARCHES_PREVIEW](state, preview) {
		state.preview = preview;
	},
	[types.SEARCHES_LAYERS](state, layers) {
		state.layers = layers;
	},
	[types.SEARCHES_ADD_LAYER](state, layer) {
		let layers = _.cloneDeep(state.layers)

		//AS--> On vérifie que le layer n'est pas déjà présent
		let layer_exists = _.find(layers, function(o) { return o.properties.id == layer.properties.id; });

		if(!layer_exists){
			layers.push(layer)
			state.layers = layers
		}
	},
	[types.SEARCHES_REMOVE_LAYER](state, layer_id) {
		let layers = _.cloneDeep(state.layers)
		//AS--> Suppresion des layers dont properties.id = layer_id
		_.remove(layers, function(o) { return o.properties.id == layer_id; });
		state.layers = layers
	},
	[types.SEARCHES_SET_GETLAYERSBYIDS_CANCELSEARCH](state, token) {
		state.getLayersByIds_cancelSearch = token;
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}