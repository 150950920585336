import * as types from '../mutation-types'
import * as turf from '@turf/turf'
import axios from '../../common/axios.js'
import _ from 'lodash'
import {gmapApi} from 'vue2-google-maps'

// initial state
const state = {
	loading: false,
	filters: {
		area_filter: false,
		area: null,
		position_name: '',
		position: false,
		range: 150,
		path: null,
		main_dechet_id: null,
		q: '',
		contact: {
			call: false,
			no_call: false
		},
		pertinance: 1,
		caracteristiques: {},
		variables: {},
		secteurs_ids: null,
		marches_ids: [],
		competences_ids: [],
		all_dechets_ids: [],
		dechets_ids: [], //Dechets ids selectionnés dans l'arbo
		order: ['companies', 1],
		dechets_qty: false,
		dechet_qty: false,
		familles_ids: [],
		capacites: [],
		zones_filter:{
			layer: null, 	//AS--> _id MongoDb du Calque parent
			zones:[]		//AS--> _ids MongoDb des Calques enfants
		},
		map_filter_mode: 'aucun',
		with_phone: false,
		with_email: false,
		contact_with_phone: false,
		contact_with_email: false,
		contact_with_email_verified: false,
		statuses: []
	},
	caracteristiques_ids: [],
	caracteristiques: false,
	variables_codes: [],
	variables: [],
	capacites_ids: [],
	capacites: {},
	marches_ids: [],
	competences_ids: [],

	dechets_qty_min_max: false,			//AS--> Quantités min et max définies par le retour de index, format : [min,max]
	dechets_qty_slider: false,			//AS--> Quantités min et max dispos en fonction des résultats, format : [min,max]. Mis à jour lors du chargement d'une recherche ou de la réponse d'une requête
	dechets_qty_active: false,

	dechet_qty_min_max: false,			//AS--> Quantités min et max définies par le retour de index, format : [min,max]
	dechet_qty_slider: false,			//AS--> Quantités min et max dispos en fonction des résultats, format : [min,max]. Mis à jour lors du chargement d'une recherche ou de la réponse d'une requête
	dechet_qty_active: false,
	
	variables_totaux: false,
	capacites_totaux: false,
	manual_dechets_qty: false,
	manual_caracteristiques: false,
	main_dechet: false, // Label du dechet principal selectionne
	last_filters_type: '',
	tmp_path: [],
	zones_selected_polygons: [],
	enabled_set_filters: true,
	code_naces: [], //?
	inexapi_filters: false,
}

// getters
const getters = {

	//AS--> 2021-05-03 - Ancienne version des filtres
	hasFiltersParams: (state, getters) => {
		if (getters.hasFiltersQ) return false
		return false;
	},
	hasFiltersActeurs: (state, getters) => {
		if (getters.hasFiltersQ) return false;
		return !_.isEqual(state.filters.familles_ids.sort(), getters.getFamillesOuvertesParamsIds.sort()) || Object.values(state.filters.capacites).length
	},
	hasFiltersDechets: (state, getters, rootState) => {
		if (getters.hasFiltersQ) return false
		let main_dechet_id = state.filters.main_dechet_id

		let last_childs_ids = []
		_.each(rootState.ui.dechets, function(dechet){
			if (dechet.data.id == main_dechet_id) {
				last_childs_ids = dechet.last_childs_ids
			}
		})

		return state.filters.dechets_ids.length != 0 && last_childs_ids.length != state.filters.dechets_ids.length
	},
	hasFiltersCaracteristiques: (state, getters) => {
		if (getters.hasFiltersQ) return false
		return Object.values(state.filters.caracteristiques).length > 0 || state.filters.pertinance != 1 || state.filters.dechets_qty != false || (typeof state.filters.dechet_qty != 'undefined' && state.filters.dechet_qty != false)
	},
	hasFiltersVariables: (state, getters) => {
		if (getters.hasFiltersQ) return false
		return Object.values(state.filters.variables).length > 0
	},
	hasFiltersSecteurs: (state, getters, rootState) => {
		if (getters.hasFiltersQ) return false
		if (state.filters.secteurs_ids === null) return false
		let diff = _.intersection(rootState.entreprises.naces_ids, state.filters.secteurs_ids)
		return diff.length < rootState.entreprises.naces_ids.length
	},
	hasFiltersContacts: (state, getters, rootState) => {
		return state.filters.with_email || state.filters.with_phone || state.filters.contact_with_email || state.filters.contact_with_email_verified || state.filters.contact_with_phone;
	},
	hasFiltersArea: (state, getters) => {
		if (getters.hasFiltersQ) return false
		return state.filters.map_filter_mode != 'aucun'
	},
	hasFiltersQ: (state) => {
		return state.filters.q != ''
	},


	//AS--> 2021-05-03 - Nouvelle version des filtres
	hasFilters_quantity: (state, getters, rootState) => (params) => {
		return state.filters.dechets_qty != false || (typeof state.filters.dechet_qty != 'undefined' && state.filters.dechet_qty != false)
	},
	hasFilters_relevance: (state, getters, rootState) => (params) => {
		return state.filters.pertinance > 1;
	},
	hasFilters_families: (state, getters, rootState) => (params) => {
		if (getters.hasFiltersQ) return false;
		return !_.isEqual(Object.values(state.filters.familles_ids).sort(), Object.values(getters.getFamillesOuvertesParamsIds).sort()) || Object.values(state.filters.capacites).length
	},
	hasFilters_waste: (state, getters, rootState) => (params) => {
		if (getters.hasFiltersQ) return false
		let main_dechet_id = state.filters.main_dechet_id

		let last_childs_ids = []
		_.each(rootState.ui.dechets, function(dechet){
			if (dechet.data.id == main_dechet_id) {
				last_childs_ids = dechet.last_childs_ids
			}
		})

		return last_childs_ids.length != 0 && last_childs_ids.length != state.filters.dechets_ids.length
	},
	hasFilters_caracteristics: (state, getters, rootState) => (params) => {
		if (getters.hasFiltersQ) return false

		if(Object.values(state.filters.caracteristiques).length == 0) return false;

		if(!params || ! params.caracteristics || !params.caracteristics.length){
			return Object.values(state.filters.caracteristiques).length > 0

		}else{
			let hasFilter = false;
			Object.keys(state.filters.caracteristiques).forEach(function(carac_filtre_id){
				params.caracteristics.forEach(function(carac_id){
					if(parseInt(carac_filtre_id) == parseInt(carac_id)) hasFilter = true;
				})
			})
			return hasFilter;
		}
	},
	hasFilters_sectors: (state, getters, rootState) => {
		return getters.hasFiltersSecteurs
	},
	hasFilters_variables: (state, getters) => (params) => {
		if (getters.hasFiltersQ) return false
		return Object.values(state.filters.variables).length > 0
	},
	hasFilters_contacts: (state, getters, rootState) => (params) => {
		return state.filters.with_email || state.filters.with_phone || state.filters.contact_with_email || state.filters.contact_with_email_verified || state.filters.contact_with_phone;
	},
	hasFilters_skills: (state, getters, rootState) => (params) => {
		return state.filters.competences_ids.length;
	},
	hasFilters_markets: (state, getters, rootState) => (params) => {
		return state.filters.marches_ids.length;
	},
	hasFilters_statuses: (state, getters) => {
		return state.filters.statuses !== false && state.filters.statuses.length > 0
	},
	

	areaTooBig: (state, getters, rootState) => {
		return rootState.ui.sourcing.limit_aire && (state.filters.area > rootState.ui.sourcing.limit_aire)
	},
	getFilterDirection: (state) => {
		return state.filters.order[1] == 1 ? 'asc' : 'desc';
	},
	canShowColonnesInfo: (state) => {
      return state.filters.q == ''
    },
    getFiltersForRequest: (state, getters, rootState, rootGetters)=> {
    	let filters = _.cloneDeep(state.filters)
    	return getters.cleanedFilter(filters)
    },
    getFiltersForExport: (state, getters, rootState, rootGetters)=> {
		let filters = {}
    	if(rootState.ui.export_id){
    		let recherche = rootGetters.getRecherche(rootState.ui.export_id)
    		filters = JSON.parse(recherche.recherche)
    	}else{
    		filters = _.cloneDeep(state.filters)
    	}
    	return getters.cleanedFilter(filters)
    },
    cleanedFilter: (state, getters, rootState, rootGetters) => (filters) => {
		//AS--> Nettoyage des familles
    	if (filters.familles_ids != undefined) filters.familles_ids = _.intersection(filters.familles_ids, rootGetters.getFamillesIds)

    	//AS--> Nettoyage des secteurs
    	if (filters.secteurs_ids != undefined) filters.secteurs_ids = _.intersection(filters.secteurs_ids, rootGetters.getSecteursIds)

    	//AS--> Corrige les valeurs 'true' et 'false' en true et false
		if (filters.capacites != undefined) {
			for (let values in filters.capacites) {
				for (let i in filters.capacites[values]) {
					if (filters.capacites[values][i] == 'true') filters.capacites[values][i] = true
					if (filters.capacites[values][i] == 'false') filters.capacites[values][i] = false
				}
			}
		}

		//AS--> Nettoyage des filtres de quantité
		if(!state.dechets_qty_active) filters.dechets_qty = false
		if(!state.dechet_qty_active) filters.dechet_qty = false

		//AS--> Si le filtre sur les statuts est un array vide, alors il n'y a pas de filtre à appliquer
		if(filters.statuses && filters.statuses.length == 0) filters.statuses = false

    	return filters
    }
}

// actions
const actions = {
	//RZ--> INIT
	initDefaultFilters({ dispatch, rootState, commit, rootGetters  }) {
		console.log('[NEW!!!] initDefaultFilters');

		//AS--> Récupération de paramètres de filtres passés dans l'URL
		let searchParams = new URLSearchParams(window.location.search.substring(1));
		let url_filters = searchParams.get('f');
		if(url_filters) url_filters = JSON.parse(atob(url_filters));

		let main_dechet_id = false
		let dechets_ids = [];
		let all_dechets_ids = [];
		for (let i in rootState.ui.dechets) {
			if (!rootState.ui.dechets[i].disabled) {
				main_dechet_id = rootState.ui.dechets[i].data['id']
				dechets_ids = rootState.ui.dechets[i].last_childs_ids
				all_dechets_ids = rootState.ui.dechets[i].all_dechets_ids
				break
			}
		}

		let position = {
			lat: null,
			lng: null
		}

		let position_name = '';
		if (rootState.ui.sourcing.position_zone) {
			let default_position = JSON.parse(rootState.ui.sourcing.position_zone)
			
			if(default_position.position){
				position = {
					lat: default_position.position.lat,
					lng: default_position.position.lng
				}

				position_name = default_position.name
			}else{
				position      = position_name = false
			}
		}

		let dechet = false
		for (var i in rootState.ui.dechets) {
			if (rootState.ui.dechets[i].data['id'] == main_dechet_id) {
				dechet = rootState.ui.dechets[i].data['titre']
			}
		}

		//AS--> Filtre par défaut sur les familles
		let familles_ids = [];
		if(url_filters && url_filters.familles_ids){
			familles_ids = url_filters.familles_ids
		}else{
			// familles_ids = rootGetters.getFamillesIds
			//AS--> Se base maintenant sur la nouvelle infos du BO par famille
			familles_ids = rootGetters.getFamillesOuvertesParamsIds
		}

		//AS--> Filtre par défaut sur les secteurs
		let secteurs_ids = null;
		if(url_filters && url_filters.secteurs_ids){
			secteurs_ids = url_filters.secteurs_ids
		}

		//AS--> Filtre sur les capacités
		let capacites = [];
		if(url_filters && url_filters.capacites){
			
			//AS--> Pour setter dans les filtres
			capacites = url_filters.capacites;

			//AS--> Pour setter dans les UI
			_.forEach(url_filters.capacites, function(capacite_values, capacite_id){
				commit('FILTERS_SET_CAPACITES_IDS', Object.keys(url_filters.capacites).map(c_id => parseInt(c_id)));
				capacite_values.forEach(function(val){
					dispatch('setCapacites', {
						capacite_id,
						value: val,
						noUpdate: true
					})
				})
			})
		}

		//AS--> Ordre de classement
		let order = ['companies', 1];
		if(url_filters && url_filters.order){
			order = url_filters.order;
		}

		let range = rootState.ui.sourcing.zone_rayon_max ? rootState.ui.sourcing.zone_rayon_max : 200;
		let area  = rootState.ui.sourcing.zone_area ? rootState.ui.sourcing.zone_area : 1000000000;
		
		let uid   = rootState.user.user.id ? rootState.user.user.id : 0;
		
		commit('FILTERS_UPDATE_MAIN_DECHET', dechet)

		let init_filters = { main_dechet_id, dechets_ids, all_dechets_ids, position, familles_ids, secteurs_ids, capacites, range, position_name, area, uid, order}

		//AS--> Regarde dans les recherches sauvegardées de l'internaute s'il n'y a pas une recherche sauvegardée automatiquement ou une recherche par défaut
		if(rootGetters.getLastSearch){
			dispatch('setSaveSearch', rootGetters.getLastSearch)
		}else{
			dispatch('setFilters', init_filters)
		}

		dispatch('getColumns', dechets_ids).then(function(data) {
			commit('UI_SET_DECHETS_COLUMNS', data.columns)
		})

		setTimeout(function(){
			commit('UI_UPDATE_ZOOMAUTO')
		}, 500)
	},
	initCaracteristiques({ dispatch, commit, state, rootState }, filtres) {
		console.log('[NEW!!!] initCaracteristiques');
		let data_filtres = state.filters.caracteristiques
		let default_custom_filters = {}
		let characteristics_min_max = {}

		//RZ--> Assigne les nouvelles valeurs de caractéristiques sur ui_filters et désactive les filtres sans valeurs
		for (let i in filtres) {
			filtres[i].visible = true

			let default_value = []
			//RZ--> Ajuste les valeurs déjà existante en fonction des nouvelles
			if (typeof data_filtres[i] != 'undefined') {
				if (filtres[i]['fonctionnement'] == 'numeric') {
					let min = data_filtres[i][0] < filtres[i].vals[0] ? filtres[i].vals[0] : data_filtres[i][0]
					let max = data_filtres[i][1] > filtres[i].vals[1] ? filtres[i].vals[1] : data_filtres[i][1]
					default_value = [min, max]
				} else if (filtres[i]['fonctionnement'] == 'textuel') {
					for (let j in data_filtres[i]) {
						if (filtres[i].vals.includes(data_filtres[i][j])) {
							default_value.push(data_filtres[i][j])
						}
					}
				}
			}

			//RZ--> Ajuste les valeurs déjà existantes en fonction des nouvelles
			if (default_value.length == 0) {
				if (filtres[i]['fonctionnement'] == 'numeric') {
					default_value = [filtres[i].vals[0], filtres[i].vals[1]]
				}
			}

			default_custom_filters[i] = default_value
		}


		commit('FILTERS_SET_CUSTOM_FILTERS', default_custom_filters)
		commit('UI_SET_UI_CUSTOM_FILTRERS', filtres)
	},
	initVariables({ dispatch, commit, state, rootState }, filtres) {
		console.log('[NEW!!!] initVariables');
		let data_filtres = state.filters.variables
		let default_variables_filters = {}

		//RZ--> Assigne les nouvelles valeurs de variables sur ui_filters et désactive les filtres sans valeurs
		for (let i in filtres) {
			filtres[i].visible = true

			let default_value = []
			//RZ--> Ajuste les valeurs déjà existante en fonction des nouvelles
			if (typeof data_filtres[i] != 'undefined') {
				let min = data_filtres[i][0] < filtres[i].min ? filtres[i].min : data_filtres[i][0]
				let max = data_filtres[i][1] > filtres[i].max ? filtres[i].max : data_filtres[i][1]
				default_value = [min, max]
			}

			//RZ--> Ajuste les valeurs déjà existante en fonction des nouvelles
			if (default_value.length == 0) {
				default_value = [filtres[i].min, filtres[i].max]
			}

			default_variables_filters[i] = default_value
		}

		commit('FILTERS_SET_VARIABLES_FILTERS', default_variables_filters)
		commit('UI_SET_UI_VARIABLES_FILTRERS', filtres)
	},
	initCapacites({ dispatch, commit, state, rootState }, filtres) {
		console.log('[NEW!!!] initCapacites');

		// commit('FILTERS_SET_CUSTOM_FILTERS', default_custom_filters)
		commit('UI_SET_UI_CAPACITES', filtres)
	},


	//RZ--> SETTER
	setPertinance({ dispatch }, pertinance) {
		console.log('[NEW!!!] setPertinance');

		dispatch('setFilters', { pertinance })
	},
	setSortCompagnies({ dispatch, state }, code) {
		console.log('[NEW!!!] setSortCompagnies');
		let direction = 1
		if (code == state.filters.order[0]) {
			direction = state.filters.order[1] * -1
		} else {
			direction = code == 'companies' || code == 'distance' || code == 'ville' || code == 'nace' ? 1 : -1;
		}

		dispatch('setFilters', { order: [code, direction] })
	},
	setQ({ dispatch, commit }, q) {
		console.log('[NEW!!!] setQ');

		dispatch('setFilters', { q, order: ['companies', 1] })
	},
	setFiltersAccueil({ dispatch, rootState, commit }, filters) {
		console.log('[NEW!!!] setFiltersAccueil');

		if (!rootState.user.user.nom) {
			dispatch('setFilters', filters).then(function() {
				commit('UI_SET_MODAL', { modal: 'connection' })
			})
			commit('UI_SET_MODAL', { modal: 'connection' })
		} else {
			commit('UI_SET_PAGE', 'results')
			dispatch('setFilters', filters)
		}
	},
	setSaveSearch({ dispatch, commit, state, rootState }, string_filters) {
		console.log('[NEW!!!] setSaveSearch');

		commit('UI_SET_CONTENT', { content: 'result' })
		commit('UI_SET_PAGE', 'results')
		commit('UI_SET_MENU', false)
		commit('UI_RECHERCHE_EDITION', false)

		let filters = {}
		let json_filters = _.cloneDeep(string_filters.recherche)
		let json_layers = string_filters.layers ? _.cloneDeep(string_filters.layers) : null

		if (json_filters.main_filters != undefined) {
			filters.position         = json_filters.main_filters.position;
			filters.range            = json_filters.main_filters.range;
			filters.path             = json_filters.main_filters.path;
			filters.zones_filter     = json_filters.main_filters.zones_filter;
			filters.main_dechet_id   = json_filters.main_filters.main_dechet_id;
			filters.q                = json_filters.main_filters.q;
			filters.contact          = json_filters.contact;
			filters.pertinance       = json_filters.pertinance;
			filters.caracteristiques = json_filters.custom_filters;
			filters.all_dechets_ids  = json_filters.all_dechets_ids;
			filters.dechets_ids      = json_filters.dechets_ids;
			filters.capacites        = json_filters.capacites;
			filters.dechet_qty       = json_filters.dechet_qty;
			filters.dechets_qty      = json_filters.dechets_qty;
			if(json_filters.secteurs_ids && json_filters.secteurs_ids.length) filters.secteurs_ids = json_filters.secteurs_ids;

			let direction = json_filters.sort_direction == 'ASC' ? 1 : -1
			filters.order = [json_filters.sort, direction];
		} else {
			filters = json_filters

			//AS--> Gère la remise en place des filtres de secteur
			if(json_filters.secteurs_ids && json_filters.secteurs_ids.length){
				filters.secteurs_ids     = json_filters.secteurs_ids;
			}else{
				//AS--> Retire secteurs_ids de l'objet filters
				filters.secteurs_ids = null
				if(rootState.entreprises.naces_ids && rootState.entreprises.naces_ids.length) filters.secteurs_ids = _.clone(_.compact(rootState.entreprises.naces_ids))
			}

		}

		//AS--> Déclenche une reprise des filtres de zone s'il y en a
		let hasZonesFilters = filters.area_filter && filters.map_filter_mode == 'zones' && filters.zones_filter?.zones?.length
		if(hasZonesFilters){
			dispatch('getLayersByIds', filters.zones_filter.zones)
		}

		//AS--> Ajout de l'id de la recherche à soustraire
		if(string_filters.recherche.substract_search) filters.substract_search = string_filters.recherche.substract_search

		//AS--> Correctif pour prendre en compte les anciennes versions sauvegardées
		if(filters.map_filter_mode == undefined){
			if(filters.area_filter){
				if(filters.path){
					filters.map_filter_mode = 'trace';
				}else{
					filters.map_filter_mode = 'rayon';
				}
			}else{
				filters.map_filter_mode = 'aucun';
			}
		}

		dispatch('set_map_state', {center: null, zoom: null}).then(function(){
			dispatch('resetAllFilters', {no_update: true}).then(function() {
				commit('UI_SET_RECHERCHE_ID', string_filters.id)

				if(Array.isArray(filters.dechets_qty)) commit('FILTERS_DECHETS_QTY_ACTIVE', true)
				if(Array.isArray(filters.dechet_qty)) commit('FILTERS_DECHET_QTY_ACTIVE', true)

				dispatch('setFilters', filters).then(function(data) {

					dispatch('updateDechetsQtySlider')
					dispatch('updateDechetQtySlider')

					dispatch('getColumns', filters.dechets_ids).then(function(data) {
						commit('UI_SET_DECHETS_COLUMNS', data.columns)
						commit('UI_SET_RECHERCHE', string_filters)

						//AS--> Remise en place des calques
						if(json_layers){
							setTimeout(function(){
								//AS--> Calques et leurs légendes
								if(json_layers.layers_opened) 				commit('UI_SWITCH_LAYER', json_layers.layers_opened)
								if(json_layers.layers_legendes_opened) 		commit('UI_SWITCH_LAYERS_LEGENDES_OPENED', json_layers.layers_legendes_opened);	
								if(json_layers.layers_opacities) 			commit('UI_LAYERS_OPACITIES', json_layers.layers_opacities);

								//AS--> Carte des entreprises
								if(typeof json_layers.layer_entreprises_open != 'undefined') 		commit('UI_SWITCH_LAYER_MARKERS', json_layers.layer_entreprises_open);
								//AS--> Heatmap des entreprises
								if(typeof json_layers.layer_heatmap_open != 'undefined') 			commit('UI_SWITCH_LAYER_HEATMAP', json_layers.layer_heatmap_open);
								//AS--> Heatmap du pvr_meta
								if(typeof json_layers.layer_heatmap_pvrmeta_open != 'undefined') 	commit('UI_SWITCH_LAYER_HEATMAP_PVRMETA', json_layers.layer_heatmap_pvrmeta_open);
							}, 250)
						}

						if(!hasZonesFilters){
							setTimeout(function(){
								commit('UI_UPDATE_ZOOMAUTO')
							}, 50)
						}

					})
				})
			})
		})
	},
	setPosition({ dispatch }, position) {
		console.log('[NEW!!!] setPosition');

		dispatch('setFilters', { position, area_filter: true })
	},
	setRange({ dispatch }, range) {
		console.log('[NEW!!!] setRange');

		dispatch('setFilters', { range, area_filter: true })
	},
	setZoneFilters({ dispatch, state, rootState }, zone) {
		console.log('[NEW!!!] setZoneFilters', zone);

		let filters = _.cloneDeep(state.filters)

		//AS--> Merge filters et zone
		let filters_merged = {...filters, ...zone}

		//AS--> Zone de la vue
		let sourcing_zone_polygons = {
			type: 'Feature',
			geometry: rootState.ui.sourcing.zone,
			properties: {}
		}

		//AS--> Calcul de l'area en prenant en compte l'intersection avec la zone de la vue
		switch(filters_merged.map_filter_mode){

			//AS--> Cas avec aucun filtre
			case 'aucun':
				zone.area = turf.area(sourcing_zone_polygons)/1000000
				break;

			//AS--> Cas du rayon
			case 'rayon':

				//AS--> Calcul du geojson du filtre
				let center = [filters_merged.position.lng, filters_merged.position.lat]
				let circle = turf.circle(center, filters_merged.range);

				//AS--> Intersection entre le filtre et la zone définie du sourcing
				let intersect = turf.intersect(sourcing_zone_polygons, circle)

				//AS--> Calcul de l'aire
				zone.area = intersect ? turf.area(intersect)/1000000 : 0
				break;

			//AS--> Cas des calques de zones
			case 'zones':
				if(rootState.searches.layers.length){
					
					//AS--> Calcul du geojson du filtre
					let zones_selected_polygons = null;
					rootState.searches.layers.forEach(function(feature){
						zones_selected_polygons = !zones_selected_polygons ? feature : turf.union(zones_selected_polygons, feature)
					})

					//AS--> Intersection entre le filtre et la zone définie du sourcing
					let intersect = turf.intersect(sourcing_zone_polygons, zones_selected_polygons)

					//AS--> Calcul de l'aire
					zone.area = intersect ? turf.area(intersect)/1000000 : 0

				//AS--> S'il n'y a pas de layers, on renvoi l'aire de la zone de sourcing
				}else{
					zone.area = turf.area(sourcing_zone_polygons)/1000000

				}
				break;

		}

		dispatch('setFilters', zone)
	},
	setSecteursIds({ dispatch, state }, ids) {
		console.log('[NEW!!!] setSecteursIds');

		let old_nbr = state.filters.secteurs_ids.length
		let secteurs_ids = _.concat(state.filters.secteurs_ids, ids)
		secteurs_ids = _.uniq(secteurs_ids)
		let new_nbr = secteurs_ids.length

		if (old_nbr == new_nbr) {
			secteurs_ids = _.pullAll(secteurs_ids, ids)
		}

		dispatch('setFilters', { secteurs_ids })
	},
	setDechetsIds({ dispatch, commit, state, rootState }, data) {
		let old_nbr = state.filters.dechets_ids.length
		let dechets_ids = _.concat(state.filters.dechets_ids, data.ids)
		dechets_ids = _.uniq(dechets_ids)
		let new_nbr = dechets_ids.length

		if (old_nbr == new_nbr) {
			dechets_ids = _.pullAll(dechets_ids, data.ids)
		}

		dispatch('getColumns', dechets_ids).then(function(data) {
			commit('UI_SET_DECHETS_COLUMNS', data.columns)

			dispatch('setFilters', { dechets_ids: data.dechets_ids, all_dechets_ids: data.all_dechets_ids })
		})


	},
	setMainDechetId({ dispatch, state, rootState, commit }, main_dechet_id) {
		console.log('[NEW!!!] setMainDechetId');
		if (main_dechet_id != state.filters.main_dechet_id) {
			commit('FILTERS_DECHETS_QTY_MIN_MAX', false)
			commit('FILTERS_DECHETS_QTY_ACTIVE', false)
			commit('FILTERS_DECHET_QTY_MIN_MAX', false)
			commit('FILTERS_DECHET_QTY_ACTIVE', false)
			commit('FILTERS_DECHETS_QTY_SOUSDECHETS', false)
			commit('FILTERS_VARIABLES_TOTAUX', false)
		}

		let dechet = false
		let dechets_ids = [];
		let all_dechets_ids = [];
		for (var i in rootState.ui.dechets) {
			if (rootState.ui.dechets[i].data['id'] == main_dechet_id) {
				dechet = rootState.ui.dechets[i].data['titre']
				dechets_ids = rootState.ui.dechets[i].last_childs_ids
				all_dechets_ids = rootState.ui.dechets[i].all_dechets_ids
			}
		}

		dispatch('setFilters', {
			main_dechet_id,
			caracteristiques: {},
			q: '',
			secteurs_ids: null,
			all_dechets_ids: all_dechets_ids,
			dechets_ids: dechets_ids,
			dechets_qty: false,
			order: ['companies', 1]
		})

		commit('FILTERS_SET_CARACTERISTIQUES_IDS', [])
		commit('FILTERS_UPDATE_MAIN_DECHET', dechet)

		dispatch('getColumns', dechets_ids).then(function(data) {
			commit('UI_SET_DECHETS_COLUMNS', data.columns)
		})

	},
	setCaracteristiques({ dispatch, commit, state, rootState }, filter) {
		console.log('[NEW!!!] setCaracteristiques');
		let _caracteristiques = _.cloneDeep(state.caracteristiques)
		if (typeof filter.check_all != 'undefined') {
			let data = []
			if (filter.check_all == true) {
				let custom_filters = rootState.ui.custom_filters
				data = custom_filters[filter.filter_id].vals
			}
			_caracteristiques[filter.filter_id] = data
		}else{
			if (_.isArray(filter.value)) {
				_caracteristiques[filter.filter_id] = filter.value

				if (!state.caracteristiques_ids.includes(filter.filter_id)) {
					commit('FILTERS_SET_CARACTERISTIQUES_IDS', _.concat(state.caracteristiques_ids, filter.filter_id))
				}
			} else {
				console.log(_caracteristiques);
				if (_caracteristiques[filter.filter_id].includes(filter.value)) {
					_caracteristiques[filter.filter_id] = _caracteristiques[filter.filter_id].filter(v => v != filter.value)
				} else {
					_caracteristiques[filter.filter_id].push(filter.value)
				}

			}
		}

		//AS--> Si des valeurs sont choisies pour cette caracteristique
		if(_caracteristiques[filter.filter_id] && _caracteristiques[filter.filter_id].length){

			if (!state.caracteristiques_ids.includes(filter.filter_id)) {
				let caracteristiques_ids = _.concat(state.caracteristiques_ids, filter.filter_id)
				commit('FILTERS_SET_CARACTERISTIQUES_IDS', caracteristiques_ids)
			}

		//AS--> Si aucune valeur n'est choisie pour cette caracteristique
		}else{

			if (state.caracteristiques_ids.includes(filter.filter_id)) {
				let caracteristiques_ids = state.caracteristiques_ids.filter(a => a !== filter.filter_id)
				commit('FILTERS_SET_CARACTERISTIQUES_IDS', caracteristiques_ids)
			}

		}

		commit('FILTERS_SET_CUSTOM_FILTERS', _caracteristiques)

		dispatch('setCaracteristiquesFilters')
	},
	setCaracteristiquesFilters({ dispatch, commit, state }, filter) {
		console.log('[NEW!!!] setCaracteristiquesFilters');

		let caracteristiques = {}
		let caracteristiques_ids = state.caracteristiques_ids

		_.forEach(state.caracteristiques, function(carac, carac_id) {
			carac_id = parseInt(carac_id, 10)
			if (caracteristiques_ids.includes(carac_id)) {
				if (typeof carac[0] == 'number') {
					caracteristiques[carac_id] = carac
				} else {
					caracteristiques[carac_id] = carac
				}
			}
		})

		dispatch('setFilters', { caracteristiques })
	},
	setVariables({ dispatch, commit, state, rootState }, filter) {
		console.log('[NEW!!!] setVariables');
		
		let _variables = _.cloneDeep(state.variables)
		if (typeof filter.check_all != 'undefined') {
			let data = []
			if (filter.check_all == true) {
				let custom_filters = rootState.ui.custom_filters
				data = custom_filters[filter.variable_code].vals
			}
			_variables[filter.variable_code] = data
		}else{
			_variables[filter.variable_code] = filter.value

			if (!state.variables_codes.includes(filter.variable_code)) {
				commit('FILTERS_SET_VARIABLES_CODES', _.concat(state.variables_codes, filter.variable_code))
			}
		}

		commit('FILTERS_SET_VARIABLES_FILTERS', _variables)

		dispatch('setVariablesFilters')
	},
	setVariablesFilters({ dispatch, commit, state }, filter) {
		console.log('[NEW!!!] setVariablesFilters');

		let variables = {}
		let variables_codes = state.variables_codes
		_.forEach(state.variables, function(variable, variable_code) {
			if (variables_codes.includes(variable_code)) {
				variables[variable_code] = variable
			}
		})

		dispatch('setFilters', { variables })
	},
	setCapacites({ dispatch, commit, state, rootState }, filter) {
		console.log('[NEW!!!] setCapacites');

		let _capacites = _.cloneDeep(state.capacites)
		if (typeof _capacites[filter.capacite_id] == 'undefined') _capacites[filter.capacite_id] = []
		
		if (typeof filter.check_all != 'undefined') {
			let data = []
			if (filter.check_all == true) {
				let capacites_data = rootState.ui.capacites
				let sourcing_capacites = rootState.ui.sourcing.capacites;
				let sourcing_capacite = sourcing_capacites[filter.capacite_id]
				if(sourcing_capacite.fonctionnement == 'boolean'){
					data = ['oui', 'non']
				}else{
					data = capacites_data[filter.capacite_id]
				}
			}
			_capacites[filter.capacite_id] = data
		} else {
			if (_capacites[filter.capacite_id].includes(filter.value)) {
				_capacites[filter.capacite_id] = _capacites[filter.capacite_id].filter(v => v != filter.value)
			} else {
				_capacites[filter.capacite_id].push(filter.value)
			}
		}
		
		//AS--> Si des valeurs sont choisies pour cette capacite
		if(_capacites[filter.capacite_id] && _capacites[filter.capacite_id].length){

			if (!state.capacites_ids.includes(filter.capacite_id)) {
				let capacites_ids = _.concat(state.capacites_ids, filter.capacite_id)
				commit('FILTERS_SET_CAPACITES_IDS', capacites_ids)
			}

		//AS--> Si aucune valeur n'est choisie pour cette capacite
		}else{

			if (state.capacites_ids.includes(filter.capacite_id)) {
				let capacites_ids = state.capacites_ids.filter(a => a !== filter.capacite_id)
				commit('FILTERS_SET_CAPACITES_IDS', capacites_ids)
			}

		}

		commit('FILTERS_SET_CAPACITES', _capacites)

		if(!filter.noUpdate) dispatch('setCapacitesFilters')
	},
	setCapacitesFilters({ dispatch, commit, state, rootState }, filter) {
		console.log('[NEW!!!] setCapacitesFilters');

		let capacites = {}

		//AS--> Mémorise les IDs des capacités présentes dans les filtres sous forme d'array
		let capacites_ids = state.capacites_ids
		
		//AS--> Récupère les données des capacités dans l'ui
		let capacites_data = rootState.ui.capacites
		
		//AS--> Récupère les données des capacités au niveau du sourcing
		let sourcing_capacites = rootState.ui.sourcing.capacites;

		_.forEach(state.capacites, function(capacite, capacite_id) {
			capacite_id = parseInt(capacite_id, 10)
			let sourcing_capacite = sourcing_capacites[capacite_id]
			if (capacites_ids.includes(capacite_id) && (typeof capacites_data[capacite_id] != 'undefined' || sourcing_capacite.fonctionnement == 'boolean')) {
				capacites[capacite_id] = capacite
				if (capacite.length > 0) {
				}
			}
		})

		//AS--> Saubegarde l'objet de capacités présentes dans les filtres
		dispatch('setFilters', { capacites })
	},



	//AS--> Set le filtre sur les marchés
	setMarches({ dispatch, commit, state, rootState }, marches_ids) {
		console.log('[NEW!!!] setMarches');
		commit('FILTERS_SET_MARCHES_IDS', marches_ids);
	},
	//AS--> Ajuste le filtre sur les marchés en fonction des options restantes après un retour de filtre sur index.php
	checkMarches({ dispatch, commit, state, rootState }) {
		console.log('[NEW!!!] checkMarches');
		let marches_ids_checked = _.intersection(state.marches_ids, state.filters.marches_ids);
		if(marches_ids_checked.length != state.filters.marches_ids.length) dispatch('setFilters', {'marches_ids' : marches_ids_checked})
	},
	//AS--> Reset le filtre sur les marchés
	resetMarches({ dispatch, state, commit, rootGetters }) {
		console.log('[NEW!!!] resetMarches');
		dispatch('setFilters', {'marches_ids' : []})
	},



	//AS--> Set le filtre sur les compétences
	setCompetences({ dispatch, commit, state, rootState }, competences_ids) {
		console.log('[NEW!!!] setCompetences');
		commit('FILTERS_SET_COMPETENCES_IDS', competences_ids);
	},
	//AS--> Ajuste le filtre sur les compétences en fonction des options restantes après un retour de filtre sur index.php
	checkCompetences({ dispatch, commit, state, rootState }) {
		console.log('[NEW!!!] checkCompetences');
		let competences_ids_checked = _.intersection(state.competences_ids, state.filters.competences_ids);
		if(competences_ids_checked.length != state.filters.competences_ids.length) dispatch('setFilters', {'competences_ids' : competences_ids_checked})
	},
	//AS--> Reset le filtre sur les compétences
	resetCompetences({ dispatch, state, commit, rootGetters }) {
		console.log('[NEW!!!] resetCompetences');
		dispatch('setFilters', {'competences_ids' : []})
	},


	setDechetsQty({ dispatch, commit, state }, filter) {
		console.log('[NEW!!!] setDechetsQty');
		let dechets_qty = filter
		dispatch('setFilters', { dechets_qty })
	},
	updateDechetsQtySlider({ commit, state }) {
		let min_index = 0
		let max_index = 0
		if(Array.isArray(state.dechets_qty_min_max)){
			min_index = state.dechets_qty_min_max[0]
			max_index = state.dechets_qty_min_max[1]
		}
		let min_filter = 0
		let max_filter = 0
		if(Array.isArray(state.filters.dechets_qty)){
			min_filter = state.filters.dechets_qty[0]
			max_filter = state.filters.dechets_qty[1]
		}
		let min_slider = min_index < min_filter ? min_index : min_filter
		let max_slider = max_index > max_filter ? max_index : max_filter
		if(!Array.isArray(state.dechets_qty_slider) || state.dechets_qty_slider[0] != min_slider || state.dechets_qty_slider[1] != max_slider){
			commit('FILTERS_DECHETS_QTY_SLIDER', [min_slider, max_slider])
		}
	},
	setDechetQty({ dispatch, commit, state }, filter) {
		console.log('[NEW!!!] setDechetQty');
		let dechet_qty = filter
		commit('FILTERS_DECHET_QTY_SLIDER', dechet_qty)
		dispatch('setFilters', { dechet_qty })
	},
	updateDechetQtySlider({ commit, state }) {
		let min_index = 0
		let max_index = 0
		if(Array.isArray(state.dechet_qty_min_max)){
			min_index = state.dechet_qty_min_max[0]
			max_index = state.dechet_qty_min_max[1]
		}
		let min_filter = 0
		let max_filter = 0
		if(Array.isArray(state.filters.dechet_qty)){
			min_filter = state.filters.dechet_qty[0]
			max_filter = state.filters.dechet_qty[1]
		}
		let min_slider = min_index < min_filter ? min_index : min_filter
		let max_slider = max_index > max_filter ? max_index : max_filter
		if(!Array.isArray(state.dechet_qty_slider) || state.dechet_qty_slider[0] != min_slider || state.dechet_qty_slider[1] != max_slider){
			commit('FILTERS_DECHET_QTY_SLIDER', [min_slider, max_slider])
		}
	},
	setFilters({ commit, dispatch, state, rootState, rootGetters }, filters) {

		//AS--> Si on avait une recherche sauvegardée en cours d'affichage
		if(rootState.ui.recherche && !rootState.ui.recherche_edition){

			//AS--> Si ce n'est pas juste un changement d'ordre
			if(!_.has(filters, 'order') || _.keys(filters).length > 1){
				
				//AS--> On supprime la référence à la recherche
				dispatch('exitSearch');
				//AS--> On vide les ignore qui pouvaient être en place avec la recherche
				filters['ignore'] = null;
			}
		}

		//RZ--> Reset du Q si le filtre change et que ce n'est pas un Q
		if (filters.q == undefined) {
			if (state.filters.q != '' && filters.order == undefined) {
				filters.q = ''
			}
		}

		//AS--> Nettoyage des familles
		if (filters.familles_ids != undefined) filters.familles_ids = _.intersection(filters.familles_ids, rootGetters.getFamillesIds)


		console.log('[NEW!!!] new filters values');

		commit('FILTERS_SET_FILTERS', filters)
		commit('FILTERS_LAST_FILTERS_TYPE', Object.keys(filters).join('_'))

		


		
		if (typeof filters.variables != "undefined") {
			let variables_codes = Object.keys(filters.variables);
			//AS--> C'est dégueulasse mais ça marche ...
			setTimeout(function(){
				commit('FILTERS_SET_VARIABLES_CODES', variables_codes);
			},20)
		}

		if (typeof filters.caracteristiques != "undefined") {
			let caracteristiques_ids = [];
			Object.keys(filters.caracteristiques).forEach(function(key){
				caracteristiques_ids.push(parseInt(key));
			})
			commit('FILTERS_SET_CARACTERISTIQUES_IDS', caracteristiques_ids)
		}

		if (typeof filters.capacites != "undefined") {
			let capacites_ids = [];
			Object.keys(filters.capacites).forEach(function(key){
				capacites_ids.push(parseInt(key));
			})
			commit('FILTERS_SET_CAPACITES_IDS', capacites_ids)
			commit('FILTERS_SET_CAPACITES', filters.capacites)
		}else{
			commit('FILTERS_SET_CAPACITES_IDS', [])
			commit('FILTERS_SET_CAPACITES', {})
		}



		if (rootState.ui.page == 'results') {
			//RZ--> Greff la liste des companies appelé aux filtres
			// let companies_calls = rootState.user.companies_calls

			// let _filters = {...state.filters, companies_calls}


			if (state.enabled_set_filters) {
				commit('UI_SET_LOADING_FIRST', true);
				// filters.append('filtres', JSON.stringify(_filters));
				dispatch('getEntreprisesAjax');
			}
		}
	},


	//RZ--> SWITCH
	switchFilterDechetsQty({ dispatch, state, commit }) {
		console.log('[NEW!!!] switchFilterDechetsQty');

		//AS--> Si le filtre était actif, on le désactive
		if(state.dechets_qty_active){
			commit('FILTERS_DECHETS_QTY_ACTIVE', false)
			dispatch('getEntreprisesAjax');

		//AS--> Si le filtre était inactif, on l'active et on ajuste les valeurs de filtre en fonction des limites du slider
		}else{
			commit('FILTERS_DECHETS_QTY_ACTIVE', true)
			if(Array.isArray(state.filters.dechets_qty)){
				let min = state.filters.dechets_qty[0] < state.dechets_qty_slider[0] ? state.dechets_qty_slider[0] : state.filters.dechets_qty[0]
				let max = state.filters.dechets_qty[1] > state.dechets_qty_slider[1] ? state.dechets_qty_slider[1] : state.filters.dechets_qty[1]
				if(min != state.filters.dechets_qty[0] || max != state.filters.dechets_qty[1]){
					dispatch('setFilters', { dechets_qty: [min, max] })
				}
			}else{
				dispatch('setFilters', { dechets_qty: [state.dechets_qty_slider[0], state.dechets_qty_slider[1]] })
			}
			dispatch('getEntreprisesAjax');
		}

	},
	switchFilterDechetQty({ dispatch, state, commit }) {
		console.log('[NEW!!!] switchFilterDechetQty');

		//AS--> Si le filtre était actif, on le désactive
		if(state.dechet_qty_active){
			commit('FILTERS_DECHET_QTY_ACTIVE', false)
			dispatch('getEntreprisesAjax');

		//AS--> Si le filtre était inactif, on l'active et on ajuste les valeurs de filtre en fonction des limites du slider
		}else{
			commit('FILTERS_DECHET_QTY_ACTIVE', true)
			if(Array.isArray(state.filters.dechet_qty)){
				let min = state.filters.dechet_qty[0] < state.dechet_qty_slider[0] ? state.dechet_qty_slider[0] : state.filters.dechet_qty[0]
				let max = state.filters.dechet_qty[1] > state.dechet_qty_slider[1] ? state.dechet_qty_slider[1] : state.filters.dechet_qty[1]
				if(min != state.filters.dechet_qty[0] || max != state.filters.dechet_qty[1]){
					dispatch('setFilters', { dechet_qty: [min, max] })
				}
			}else{
				dispatch('setFilters', { dechet_qty: [state.dechet_qty_slider[0], state.dechet_qty_slider[1]] })
			}
			dispatch('getEntreprisesAjax');
		}
	},
	switchFilterContactNoCall({ dispatch, state }) {
		console.log('[NEW!!!] switchFilterContactNoCall');
		let new_value = { ...state.filters.contact, no_call: !state.filters.contact.no_call }

		dispatch('setFilters', { contact: new_value })
	},
	switchFilterContactCall({ dispatch, state }) {
		console.log('[NEW!!!] switchFilterContactCall');
		let new_value = { ...state.filters.contact, call: !state.filters.contact.call }

		dispatch('setFilters', { contact: new_value })
	},
	switchFilterCarac({ dispatch, state, commit }, item) {
		console.log('[NEW!!!] switchFilterCarac', item);

		let caracteristiques_ids = []
		let select_all = false

		if (state.caracteristiques_ids.includes(item.id)) {
			caracteristiques_ids = state.caracteristiques_ids.filter(a => a !== item.id)
		} else {
			caracteristiques_ids = _.concat(state.caracteristiques_ids, item.id)
			select_all = true
		}

		commit('FILTERS_SET_CARACTERISTIQUES_IDS', caracteristiques_ids)
		if (item.fonctionnement == 'textuel') {
			dispatch('setCaracteristiques', {filter_id: item.id, check_all: select_all})
		}else{
			dispatch('setCaracteristiquesFilters')
		}
	},
	switchFilterVariable({ dispatch, state, commit }, item) {
		console.log('[NEW!!!] switchFilterVariable', item);

		let variables_codes = []
		let select_all = false

		if (state.variables_codes.includes(item.id)) {
			variables_codes = state.variables_codes.filter(a => a !== item.id)
		} else {
			variables_codes = _.concat(state.variables_codes, item.id)
			select_all = true
		}

		commit('FILTERS_SET_VARIABLES_CODES', variables_codes)
		dispatch('setVariablesFilters')
	},
	switchFilterCapacite({ dispatch, state, commit }, capacite_id) {
		console.log('[NEW!!!] switchFilterCapacite', capacite_id);

		let capacites_ids = []
		let select_all = false;
		if (state.capacites_ids.includes(capacite_id)) {
			capacites_ids = state.capacites_ids.filter(a => a !== capacite_id)
		} else {
			capacites_ids = _.concat(state.capacites_ids, capacite_id)
			select_all = true;
		}

		commit('FILTERS_SET_CAPACITES_IDS', capacites_ids)
		dispatch('setCapacites', {capacite_id, check_all: select_all})
	},
	switchFilterStatut({ dispatch, state, commit, rootState }, slug) {
		console.log('[NEW!!!] switchFilterStatut', slug);

		let allFilters = rootState.ui.statuses.map(a => a.slug)
		allFilters.push(null)

		//AS--> Prend l'état actuel du filtre sur les statuts et le transforme en array avec tous les slugs s'il est FALSE
		let filters = _.clone(state.filters.statuses)
		if(filters === false) filters = allFilters

		//AS--> Ajoute ou retire le statut en question
		if (filters.includes(slug)) {
			filters = filters.filter(a => a !== slug)
		} else {
			filters = _.concat(filters, slug)
		}

		//AS--> Compare à la liste des statuts disponibles et si tous les statuts sont cochés, on passe filters à false
		if (filters.length == rootState.ui.statuses.length+1) filters = false

		dispatch('setFilters', { statuses: filters })
	},
	selectAllFilterStatut({ dispatch, state, commit, rootState }) {
		console.log('[NEW!!!] selectAllFilterStatut');

		let allFilters = rootState.ui.statuses.map(a => a.slug)
		allFilters.push(null)

		dispatch('setFilters', { statuses: allFilters })
	},
	switchFamilleId({ dispatch, state, commit }, famille_id) {
		console.log('[NEW!!!] switchFamilleId', famille_id);

		let familles_ids = []
		if (state.filters.familles_ids.includes(famille_id)) {
			familles_ids = state.filters.familles_ids.filter(a => a !== famille_id)
		} else {
			familles_ids = _.concat(state.filters.familles_ids, famille_id)
		}

		dispatch('setFilters', { familles_ids })
	},
	switchCapacite({ dispatch, state, commit }, capacite_id) {
		console.log('[NEW!!!] switchCapacite', capacite_id);
		let _capacites = _.cloneDeep(state.filters.capacites)

		if (typeof _capacites[capacite_id] != 'undefined') {
			if (_capacites[capacite_id] == true) {
				_capacites[capacite_id] = false;
			}else{
				_capacites[capacite_id] = null;
			}
		}else{
			_capacites[capacite_id] = true;
		}

		let capacites = {}
		_.each(_capacites, function(v,k){
			if (v != undefined) {
				capacites[k] = v
			}
		})

		dispatch('setFilters', { capacites })
	},
	switchFilter({ dispatch, state, commit }, filter_name) {
		console.log('[NEW!!!] switchFilter', filter_name);

		let filters = {};
		filters[filter_name] = !state.filters[filter_name];

		dispatch('setFilters', filters)
	},

	//RZ--> RESET
	resetFilterCaracteristiques({ dispatch, state, commit }) {
		console.log('[NEW!!!] resetFilterCaracteristiques');
		let filters = {
			contact: {
				call: false,
				no_call: false
			},
			
			caracteristiques: {},

			pertinance: 1,
			dechets_qty: false,
			dechet_qty: false,
		}

		commit('FILTERS_DECHETS_QTY_ACTIVE', false)
		commit('FILTERS_DECHET_QTY_ACTIVE', false)
		commit('FILTERS_SET_CARACTERISTIQUES_IDS', [])
		dispatch('setFilters', filters)
	},
	resetFilterStatuts({ dispatch, state, commit }) {
		console.log('[NEW!!!] resetFilterStatuts');
		dispatch('setFilters', {statuses : []})
	},
	resetFilterVariables({ dispatch, state, commit }) {
		console.log('[NEW!!!] resetFilterVariables');
		let filters = {
			variables: {}
		}

		commit('FILTERS_SET_VARIABLES_CODES', [])
		dispatch('setFilters', filters)
	},
	resetFilterQty({ dispatch, state, commit }) {
		console.log('[NEW!!!] resetFilterQty');
		let filters = {
			dechets_qty: false,
			dechet_qty: false,
		}
		commit('FILTERS_DECHETS_QTY_ACTIVE', false)
		commit('FILTERS_DECHET_QTY_ACTIVE', false)
		dispatch('setFilters', filters)
	},
	resetSecteurs({ dispatch, commit, state, rootState, rootGetters }) {
		console.log('[NEW!!!] resetSecteurs');

		//AS--> Si les filtres par secteurs ne sont pas activés sur la vue
		if(!rootGetters.canFilterSectors){
			let filters = _.cloneDeep(state.filters)
			filters.secteurs_ids = null
			commit('FILTERS_SET_FILTERS', filters)

		//AS--> Si les filtres par secteurs sont activés sur la vue
		}else{

			let origine_filter_secteurs_ids = _.cloneDeep(state.filters.secteurs_ids)
			let new_filter_secteurs_ids = null

			if(rootState.entreprises.naces_ids && rootState.entreprises.naces_ids.length) new_filter_secteurs_ids = _.clone(_.compact(rootState.entreprises.naces_ids))

			//AS--> Si on passe de null à la liste complète des NACE, le résultat est le même donc on modifie la donnée sans repasser par tout le setFilters qui redéclenche une requête
			if(origine_filter_secteurs_ids === null && new_filter_secteurs_ids && new_filter_secteurs_ids.length){
				let filters = _.cloneDeep(state.filters)
				filters.secteurs_ids = new_filter_secteurs_ids
				commit('FILTERS_SET_FILTERS', filters)

			}else{
				dispatch('setFilters', {secteurs_ids: new_filter_secteurs_ids})
			}

		}

	},
	disabledSecteurs({ dispatch, state }) {
		console.log('[NEW!!!] disabledSecteurs');
		let filters = {
			secteurs_ids: [],
		}

		dispatch('setFilters', filters)
	},
	resetDechets({ dispatch, state, rootState }) {
		console.log('[NEW!!!] resetDechets');
		let main_dechet_id = state.filters.main_dechet_id

		let dechets_ids = [];
		let all_dechets_ids = [];
		for (let i in rootState.ui.dechets) {
			if (!rootState.ui.dechets[i].disabled && rootState.ui.dechets[i].data['id'] == main_dechet_id) {
				dechets_ids = rootState.ui.dechets[i].last_childs_ids
				all_dechets_ids = rootState.ui.dechets[i].all_dechets_ids
				break
			}
		}

		let filters = {
			dechets_ids,
			all_dechets_ids
		}

		dispatch('setFilters', filters)
	},
	disabledDechets({ dispatch, state, rootState }) {
		console.log('[NEW!!!] disabledDechets');

		let dechets_ids = [];
		let all_dechets_ids = [];

		let filters = {
			dechets_ids,
			all_dechets_ids
		}

		dispatch('setFilters', filters)
	},
	resetParams({ dispatch, state, commit }) {
		console.log('[NEW!!!] resetParams');
		
		//AS--> Ne fait plus rien
	},
	resetSubstractSearch({ dispatch, state, commit }) {
		console.log('[NEW!!!] resetParams');
		
		if(state.filters.substract_search){
			let filters = {
				substract_search: null
			}
			dispatch('setFilters', filters)
		}

	},
	resetActors({ dispatch, state, commit, rootGetters }) {
		console.log('[NEW!!!] resetActors');
		let filters = {
			familles_ids: rootGetters.getFamillesOuvertesParamsIds,
			capacites: {}
		}

		commit('FILTERS_SET_CAPACITES_IDS', [])
		dispatch('setFilters', filters)
	},
	resetContacts({ dispatch, state, commit, rootGetters }) {
		console.log('[NEW!!!] resetContacts');
		let filters = {
			with_phone: false,
			with_email: false,
			contact_with_email: false,
			contact_with_email_verified: false,
			contact_with_phone: false
		}
		dispatch('setFilters', filters)
	},
	resetGeographique({ dispatch, state, rootState, commit, rootGetters }) {
		console.log('[NEW!!!] resetGeographique');
		
		let zone_filters = {}

		let position = false //AS--> Cause une erreur légère. Mettre un array {"lat": 0,"lng": 0} cause une erreure bien plus grave (carte que se décharge et ne s'affiche plus)

		let position_name = false;

		if (rootState.ui.sourcing.position_zone) {
			let default_position = JSON.parse(rootState.ui.sourcing.position_zone)
			
			if(default_position.position){
				position = {
					lat: default_position.position.lat,
					lng: default_position.position.lng
				}

				position_name = default_position.name
			}else{
				position = position_name = false
			}
		}

		zone_filters.area_filter     = false
		zone_filters.range           = this.rayon_maxi
		zone_filters.position        = position
		zone_filters.path            = null
		zone_filters.position_name   = position_name
		
		zone_filters.area            = rootState.ui.sourcing.zone_area
		
		zone_filters.zones_filter    = {layer: null, zones: []}

		zone_filters.map_filter_mode = 'aucun';

		dispatch('setZoneFilters', zone_filters);
	},

	resetAllFilters({ dispatch, state, rootState, commit }, params){
		state.enabled_set_filters = false
		dispatch('resetSubstractSearch');
		dispatch('resetFilterCaracteristiques');
		dispatch('resetFilterVariables');
		dispatch('resetSecteurs');
		dispatch('resetDechets');
		dispatch('resetParams');
		dispatch('resetActors');
		dispatch('resetContacts');
		dispatch('resetGeographique')
		dispatch('resetFilterQty')
		dispatch('resetFilterStatuts')
		commit('UI_SET_RECHERCHE_ID', null);
		state.enabled_set_filters = true

		//AS--> Reset les layers
		dispatch('resetPrimaryLayers')
		dispatch('resetParamsLayers')
		dispatch('resetFamilyLayers')

		//AS--> Déclenche un update
		if(!params || !params.no_update){
			commit('UI_SET_LOADING_FIRST', true);
			dispatch('getEntreprisesAjax');
		}

		//AS--> Supprime la recherche sauvegardée par défaut
		var data = new FormData();
		data.append('nom', '__last__')
		data.append('pardefaut', false)
		data.append('recherche', 'delete');
		axios.post('/plugin_projet/plugin_projet_iannuaire/save_recherche', data)
	},

	checkAllFilters({ dispatch, state }){
		state.enabled_set_filters = false
		dispatch('checkMarches')
		dispatch('checkCompetences')
		state.enabled_set_filters = true
	},

	//RZ--> GET
	getColumns({ dispatch, state, rootState }, dechets_ids) {
		console.log('[NEW!!!] getColumns');

		let all_dechets_ids = []
		let columns = []
		let selected_ids = dechets_ids
		let main_dechet_id = state.filters.main_dechet_id
		let arbo = rootState.ui.dechets

		let recurcive = function(item, level_id) {
			let ids_in = []
			if (item.level > 1) {
				if (typeof item.last_childs_ids == 'undefined') item.last_childs_ids = []
				ids_in = _.intersection(selected_ids, item.last_childs_ids)
			}

			//RZ--> Si le niveau de l'arbo est entierement coché
			if (ids_in.length == item.last_childs_ids.length) {
				//RZ--> On ajoute une barre et ces ids liés
				columns[level_id].levels.push({
					nom: item.data.titre,
					dechets_ids: item.all_dechets_ids,
				})
				//RZ--> On ajoute les dechets
				all_dechets_ids = _.concat(all_dechets_ids, item.all_dechets_ids)

				//RZ--> Sinon on regarde sur les enfants
			} else {
				if (typeof item.childs != 'undefined') {
					for (let i in item.childs) {
						recurcive(item.childs[i], level_id)
					}
				}
			}
		}

		//RZ--> récupère la clé du niveau en cours
		let main_arbo_id = null;
		for (let i in arbo) {
			if (arbo[i].data.id == main_dechet_id) {
				main_arbo_id = i
				break
			}
		}

		//RZ--> Si on a un dechet principal de selectionné
		if (typeof arbo[main_arbo_id] != 'undefined' && typeof arbo[main_arbo_id]['childs'] != 'undefined') {
			//RZ--> Cherche les column dans le niveau 2
			for (let arbo_id in arbo[main_arbo_id]['childs']) {
				let item = arbo[main_arbo_id]['childs'][arbo_id]

				//RZ--> Cherche si ses enfants contiennent les ids selectionnés
				let ids_in = _.intersection(selected_ids, item['last_childs_ids'])

				//RZ--> Si le niveau 2 de l'arbo est partielement coché ou coché on ajoute la column
				if (ids_in.length > 0) {
					if (ids_in.length == item['last_childs_ids'].length) {
						all_dechets_ids = _.concat(all_dechets_ids, item['all_dechets_ids'])
					}

					columns.push({
						id: item.data.id,
						nom: item.data.titre,
						ordre: item.data.ordre,
						all_dechets_ids: item['all_dechets_ids']
					})
				}
			}

			//RZ--> Pour chaque column
			for (let i in columns) {
				if (typeof arbo[main_arbo_id]['childs'][columns[i].id] != "undefined") {
					columns[i].levels = [];
					if (typeof arbo[main_arbo_id]['childs'][columns[i].id]['childs'] != "undefined") {
						recurcive(arbo[main_arbo_id]['childs'][columns[i].id], i)
					}else{
						columns[i].levels = [{
							nom: columns[i].nom,
							dechets_ids: columns[i].all_dechets_ids
						}];
					}
				}
			}
		}

		all_dechets_ids = _.uniq(all_dechets_ids)

		return { all_dechets_ids, columns, dechets_ids }
	},


	getEntreprisesAjax({ dispatch, commit, rootState, state, getters }, offset) {

		if (undefined == offset) offset = 0

		if(offset == 0){
			commit('ENTREPRISES_GRAPHS', null)
			commit('ENTREPRISES_GRAPHS_ASSOLEMENT', null)
			commit('ENTREPRISES_TOTAL', 'loading')
		}

		//AS--> Annule la requête en cours si elle s'exécute toujours
		if (undefined != state.axios_source) state.axios_source.cancel();

		//AS--> Stock un nouveau token d'exécution Axios
		commit('FILTERS_SET_ABORT_TOKEN');

		//AS--> Envoie à Axios la référence du token d'annulation
		let axios_config = {
			cancelToken: state.axios_source.token
		}

		//AS--> Enregistre la dernière requête
		if(offset == 0){
			let recherche = {
				nom : '__last__',
				pardefaut : false,
				recherche : state.filters
			}
			if(getters.hasLastRecherche) recherche.update = true
			axios.post('api=webinex/saveUserSearch', recherche, {headers: {'Content-Type': 'application/json'}})
		}

		//AS--> Si ce ne sont pas que des changements d'ordre, on met aussi la carte en loading et les stats aussi
		if (state.last_filters_type != 'order' && offset === 0){
			commit('UI_SET_LOADING_MAP', true);
			commit('ENTREPRISES_STATS_FAMILLES', 'loading')
		}

		//AS--> Charge les résultats des filtres
		commit('FILTERS_LOADING', true);
		//AS--> Fait la requête de récupération des résultats des filtres à afficher dans la liste des entreprises
		axios.post('/sourcing_api/index.php?offset=' + offset, getters.getFiltersForRequest, axios_config)
			.then(function(response) {

				if (response.data != 'CONNEXION') {

					if (offset === 0) {

						let table = document.getElementById('table_entreprises');
						if (table) table.scrollTop = 0
						if (table) table.scrollLeft = 0

						commit('UI_SET_PLAGE', { 'min': 0, 'max': 50 });

						//AS--> Mémorise le total
						if (typeof response.data.total != 'undefined') {
							commit('ENTREPRISES_TOTAL', response.data.total)
						}

						//AS--> Mémorise le total d'entreprises dans la liste
						if (typeof response.data.total_liste != 'undefined') {
							commit('ENTREPRISES_TOTAL_LISTE', response.data.total_liste)
						}

						//AS--> Mémorise la quantite de dechets total
						if (typeof response.data.qty_dechets != 'undefined') {
							commit('ENTREPRISES_QUANTITE', response.data.qty_dechets)
						}

						//AS--> Mémorise les stats de familles
						if (typeof response.data.nbr_familles != 'undefined') {
							commit('ENTREPRISES_FAMILLES_NBR', response.data.nbr_familles)
						}

						//AS--> Mémorise la quantite de Potentiel méthanogène
						if (typeof response.data.pvr_meta != 'undefined') {
							commit('ENTREPRISES_PVR_META', response.data.pvr_meta)
						}


						// if(!getters.hasFiltersQ){
							//AS--> Mémorise les filtres
							let carac_filtres = false;
							//AS--> Si les filtres sont renvoyés avec la requête, on prend ceux-ci
							if (typeof response.data.filtres != 'undefined') {
								if (response.data.filtres && Object.values(response.data.filtres).length != 0) carac_filtres = response.data.filtres;

							//AS--> Sinon, on prend les filtres reçu avec le init_app
							} else {
								_.forEach(rootState.ui.dechets, function(dechet) {
									if (dechet.data.id == rootState.filters.filters.main_dechet_id) {
										if (dechet.filtres && Object.values(dechet.filtres).length != 0) {
											carac_filtres = dechet.filtres;
										}
									}
								})
							}
							dispatch('initCaracteristiques', carac_filtres);


							//AS--> Mémorise les filtres de capacités
							let capacites_filtres = false;
							//AS--> Si les filtres sont renvoyés avec la requête, on prend ceux-ci
							if (typeof response.data.filtres != 'undefined') {
								if (response.data.filtres_capacites && Object.values(response.data.filtres_capacites).length != 0) capacites_filtres = response.data.filtres_capacites;
							}
							dispatch('initCapacites', capacites_filtres);



							//AS--> Mémorise les naces
							if (typeof response.data.naces_ids != 'undefined') {
								let naces_ids = response.data.naces_ids.length == 0 ? false : response.data.naces_ids
								commit('NACES_IDS', naces_ids)
								if(state.filters.secteurs_ids === null && naces_ids){
									dispatch('resetSecteurs');
								}
								// Si aucun filtre n'est sélectionné, on sélectionne tous les naces - Comportement supprimé
								// if (state.filters.secteurs_ids.length == 0) {
								// 	commit('FILTERS_SET_FILTERS', {secteurs_ids: naces_ids})
								// }
							}


							//AS--> Mémorise les variables
							if (typeof response.data.filtres_variables != 'undefined') {
								let filtres_variables = typeof response.data.filtres_variables != 'object' || _.keys(response.data.filtres_variables).length == 0 ? false : response.data.filtres_variables
								commit('VARIABLES', filtres_variables)
								dispatch('initVariables', filtres_variables);
							}


							//AS--> Mémorise les marchés
							if (typeof response.data.filtres_marches != 'undefined') {
								let filtres_marches = response.data.filtres_marches == null || response.data.filtres_marches.length == 0 ? [] : response.data.filtres_marches
								// commit('MARCHES', filtres_marches)
								dispatch('setMarches', filtres_marches);
							}


							//AS--> Mémorise les compétences
							if (typeof response.data.filtres_competences != 'undefined') {
								let filtres_competences = response.data.filtres_competences == null || response.data.filtres_competences.length == 0 ? [] : response.data.filtres_competences
								// commit('competenceS', filtres_competences)
								dispatch('setCompetences', filtres_competences);
							}


							//AS--> Mémorise les graphs
							if (typeof response.data.graphs != 'undefined') {
								commit('ENTREPRISES_GRAPHS', response.data.graphs)
							}

							//AS--> Mémorise le nombre de nouveaux
							if (typeof response.data.news != 'undefined') {
								commit('ENTREPRISES_NEWS', response.data.news)
							}

							//RZ--> Mémorise la quantite de dechets min et max
							if (typeof response.data.qty_max != 'undefined') {
								commit('FILTERS_DECHETS_QTY_MIN_MAX', [Math.floor(response.data.qty_min), Math.ceil(response.data.qty_max)])
								dispatch('updateDechetsQtySlider')

								//AS--> Mémorise les quantités par sous-famille de déchet
								let sousDechets_totaux = {};
								_.forEach(rootState.ui.dechets_columns, function(column, index){
									sousDechets_totaux[column.id] = typeof response.data['qty_'+column.id] != 'undefined' ? Math.round(response.data['qty_'+column.id]) : 0;
								})
								commit('FILTERS_DECHETS_QTY_SOUSDECHETS', sousDechets_totaux)
							}

							//RZ--> Mémorise la quantite de dechets min et max
							if (typeof response.data.qty_dechet_max != 'undefined') {
								commit('FILTERS_DECHET_QTY_MIN_MAX', [Math.floor(response.data.qty_dechet_min), Math.ceil(response.data.qty_dechet_max)])
								dispatch('updateDechetQtySlider')
							}


							//AS--> Mémorise les totaux par variable
							if (typeof response.data.totaux_variables != 'undefined') {
								commit('FILTERS_VARIABLES_TOTAUX', response.data.totaux_variables)
							}

							//AS--> Mémorise les totaux par capacite
							if (typeof response.data.totaux_capacites != 'undefined') {
								commit('FILTERS_CAPACITES_TOTAUX', response.data.totaux_capacites)
							}

							//AS--> Met à jour les valeurs des filtres pour ne garder que les valeurs qui sont encore disponibles
							dispatch('checkAllFilters')
						
						// }

					}

					//AS--> Gestion des données d'entreprise
					if (typeof response.data.entreprises != 'undefined') {
						let entreprises = offset === 0 ? response.data.entreprises : _.union(rootState.entreprises.raw, response.data.entreprises);

						commit('ENTREPRISES_INIT', entreprises)

						// if(rootState.ui.page == 'search') commit('UI_SET_PAGE', 'results')

					}

					commit('UI_SET_LOADING', false)
					commit('UI_SET_LOADING_FIRST', false);

				}
			}).catch(function(error) {
				commit('FILTERS_LOADING', false)
				commit('UI_SET_LOADING', false)
				// commit('UI_SET_LOADING_FIRST', false);
			})


		//AS--> Déclenche en simultané le chargement des markers et des stats
		if (state.last_filters_type != 'order' && offset == 0){
			dispatch('getMapAjax')
			dispatch('getStatsAjax')
		}

	},

	//AS--> Récupération des stats de la recherche
	getStatsAjax({ dispatch, commit, rootState, state, getters }) {

		//AS--> Annule la requête en cours si elle s'exécute toujours
		if (undefined != state.axios_source_stats) state.axios_source_stats.cancel();

		//AS--> Stock un nouveau tocken d'exécution Axios
		commit('FILTERS_SET_ABORT_TOKEN_STATS');
		//AS--> Envoie à Axios la référence du token d'annulation
		let axios_config_stats = {
			cancelToken: state.axios_source_stats.token
		}

		axios.post('api=inex/companies/stats/{view_code}', getters.getFiltersForRequest, axios_config_stats)
			.then(function(response) {
				if (response.data != 'CONNEXION') {

					//AS--> Mémorise le total
					if (typeof response.data.total_site != 'undefined') {
						commit('ENTREPRISES_TOTAL', response.data.total_site)
					}

					//AS--> Mémorise les stats de capacites
					if (typeof response.data.stats_familles != 'undefined') {
						commit('ENTREPRISES_STATS_FAMILLES', response.data.stats_familles)
					}else{
						commit('ENTREPRISES_STATS_FAMILLES', [])
					}
					
				}
			}).catch(function(error) {
				console.log(error);
				commit('ENTREPRISES_STATS_FAMILLES', [])
			})

	},

	//RZ--> Récupération des coordonnées des entreprises pour les markers
	getMapAjax({ dispatch, commit, rootState, state, getters }) {

		//AS--> Annule la requête en cours si elle s'exécute toujours
		if (undefined != state.axios_source_maps) state.axios_source_maps.cancel();

		//AS--> Stock un nouveau tocken d'exécution Axios
		commit('FILTERS_SET_ABORT_TOKEN_MAPS');
		//AS--> Envoie à Axios la référence du token d'annulation
		let axios_config_map = {
			cancelToken: state.axios_source_maps.token
		}

		//AS--> Demande à sauvegarder tous les IDs des entreprises en résultat de la recherche
		let save_search = rootState.ui.recherche_id ? '?save_search_result='+rootState.ui.recherche_id : ''

		axios.post('/sourcing_api/map.php'+save_search, getters.getFiltersForRequest, axios_config_map)
			.then(function(response) {
				if (response.data != 'CONNEXION') {

					if(response.data.stats){
						//AS--> Mémorise le total
						if (typeof response.data.stats.total != 'undefined') 
							commit('ENTREPRISES_TOTAL', response.data.stats.total);

						//AS--> Mémorise les stats de capacites
						if (typeof response.data.stats.stats_familles != 'undefined') 
							commit('ENTREPRISES_STATS_FAMILLES', response.data.stats.stats_familles)
					}

					//AS--> Mémorise le mode de calcul
					if (typeof response.data.mode_calcul != 'undefined') 
						commit('SET_MODE_CALCUL', response.data.mode_calcul)

					//AS--> Mémorise si les résultats sont groupés
					commit('SET_MAP_GROUPED', typeof response.data.map_grouped != 'undefined' ? response.data.map_grouped : false)

					let all_ids = []

					_.forEach(response.data, function(m) {
						all_ids.push(m._id)
					})

					commit('SET_COMPANIES_IDS', all_ids)

					response.data.markers.map(function(marker) {
						marker['geometry'] = { 'coordinates': marker.p };
						delete marker.p;
						return marker;
					});
					commit('SET_MARKERS', response.data.markers);
				}
				commit('UI_SET_LOADING_MAP', false);
			}).catch(function(error) {
				commit('UI_SET_LOADING_MAP', false);
				console.log(error);
			}).finally(function(){
				commit('UI_SET_FIRST_DATA_LOADED');
			})

	},

	getGraphsAjax({ dispatch, commit, rootState, state, getters }) {

		//AS--> Annule la requête en cours si elle s'exécute toujours
		if (undefined != state.axios_source_graphs) state.axios_source_graphs.cancel();

		//AS--> Stock un nouveau tocken d'exécution Axios
		commit('FILTERS_SET_ABORT_TOKEN_GRAPHS');

		//AS--> Envoie à Axios la référence du token d'annulation
		let axios_config = {
			cancelToken: state.axios_source_graphs.token
		}

		axios.post('api=inex/graphs/', getters.getFiltersForRequest, axios_config)
			.then(function(response) {

				if (response.data != 'CONNEXION') {

					//AS--> Mémorise les graphs
					if (typeof response.data != 'undefined') {

						if(response.data.TonnageParInterface?.stats){
							dispatch('affecteGraphCouleursDechets', {
								code : 'couleurs_dechets',
								data : response.data.TonnageParInterface.stats,
								key  : 'id'
							})
						}
						if(response.data.MethanogenicPotentialPerInterface?.stats){
							dispatch('affecteGraphCouleursDechets', {
								code : 'couleurs_dechets',
								data : response.data.MethanogenicPotentialPerInterface.stats,
								key  : 'id'
							})
						}
						if(response.data.TonnageParInterfaceDetail?.stats){
							dispatch('affecteGraphCouleursDechets', {
								code : 'couleurs_dechets_details',
								data : response.data.TonnageParInterfaceDetail.stats,
								key  : 'id'
							})
						}
						if(response.data.MethanogenicPotentialPerInterfaceDetail?.stats){
							dispatch('affecteGraphCouleursDechets', {
								code : 'couleurs_dechets_details',
								data : response.data.MethanogenicPotentialPerInterfaceDetail.stats,
								key  : 'id'
							})
						}
						
						commit('ENTREPRISES_GRAPHS', response.data)

					}

				}
			}).catch(function(error) {
				// commit('FILTERS_LOADING', false)
				// commit('UI_SET_LOADING', false)
				// commit('UI_SET_LOADING_FIRST', false);
			})
	},

	getGraphsAssolementAjax({ dispatch, commit, rootState, state }) {

		if(rootState.filters.filters.map_filter_mode == 'aucun'){
			setTimeout(function(){
				commit('ENTREPRISES_GRAPHS_ASSOLEMENT', {'detail':[{'msg':'pas de filtre'}]})
			},10)

		}else{

			//AS--> Annule la requête en cours si elle s'exécute toujours
			if (undefined != state.axios_source_graphs_assolement) state.axios_source_graphs_assolement.cancel();

			//AS--> Stock un nouveau tocken d'exécution Axios
			commit('FILTERS_SET_ABORT_TOKEN_GRAPHS_ASSOLEMENT');

			//AS--> Envoie à Axios la référence du token d'annulation
			let axios_config = {
				cancelToken: state.axios_source_graphs_assolement.token
			}

			switch(rootState.filters.filters.map_filter_mode){
				case 'rayon':
					let url = 'api=inex/agriculture/exploitation/type?latitude='+rootState.filters.filters.position.lat+'&longitude='+rootState.filters.filters.position.lng+'&radius='+rootState.filters.filters.range
					// console.log(url);
					axios.post(url, null, axios_config)
						.then(function (response) {
							//AS--> Mémorise les graphs
							if (typeof response.data != 'undefined') commit('ENTREPRISES_GRAPHS_ASSOLEMENT', response.data)


						}).catch(function (error) {
							//AS--> Mémorise l'erreur
							if (typeof error.response == 'undefined'){
								commit('ENTREPRISES_GRAPHS_ASSOLEMENT', null)
							}else if (typeof error.response.data != 'undefined'){
								commit('ENTREPRISES_GRAPHS_ASSOLEMENT', error.response.data)
							}

						})
					break;
				case 'zones':
					// let body = rootState.ui.env == 'DEV' ? {zones_filter:{"layer": "$5ec561d7f22d2d518715356","zones": ["5ecfe99986d5be32cc361450"]}} : {zones_filter:rootState.filters.filters.zones_filter};
					let body = {zones_filter:{layer: '', zones:rootState.filters.filters.zones_filter.zones}}
					// console.log(body);
					axios_config.headers = {'Content-Type': 'application/json'}
					axios.post('api=inex/agriculture/exploitation/type', body, axios_config)
						.then(function (response) {
							//AS--> Mémorise les graphs
							if (typeof response.data != 'undefined') commit('ENTREPRISES_GRAPHS_ASSOLEMENT', response.data)

						}).catch(function (error) {
							//AS--> Mémorise l'erreur
							if (typeof error.response == 'undefined'){
								commit('ENTREPRISES_GRAPHS_ASSOLEMENT', null)
							}else if (typeof error.response.data != 'undefined'){
								commit('ENTREPRISES_GRAPHS_ASSOLEMENT', error.response.data)
							}

						})
					break;
			}
		}
	},


	loadApiInexFilters({ dispatch, commit, rootState, state, getters }) {

		let vars = '?'
		if(rootState.ui.map_zone_google.geoJsonActif) vars += encodeURI('geometry=' + JSON.stringify(rootState.ui.map_zone_google.geoJsonActif))
		axios.get('api=inex/views/{view_code}/filtres'+vars)
			.then(function (response) {
				commit('FILTERS_SET_INEXAPI_FILTERS', response.data);
				
			}).catch(function (error) {

			})
	}




	//Utilisé nul part
	// getMainDechet({rootState, state}){
	// 	let dechet = false
	// 	for (var i  in rootState.ui.dechets) {
	// 		if (rootState.ui.dechets[i].data['id'] == state.main_filters.main_dechet_id) {
	// 			dechet = rootState.ui.dechets[i].data['titre']
	// 		}
	// 	}
	// 	return dechet
	// },
	// updateMainFilter({dispatch, commit, state, rootState}){
	// 	let main_filters = _.cloneDeep(state.main_filters)
	// 	commit('FILTERS_LOADING', true)
	// 	commit('UI_SET_LOADING', true)

	// 	let new_filters = false
	// 	if(main_filters.main_dechet_id == null){
	// 		rootState
	// 		let dechet = false
	// 		for (let i in rootState.ui.dechets) {
	// 			if(!rootState.ui.dechets[i].disabled){
	// 				main_filters.main_dechet_id = rootState.ui.dechets[i].data['id']
	// 				break
	// 			}
	// 		}
	// 		new_filters = true
	// 	}
	// 	if(main_filters.position.lat == null){
	// 		let default_position = JSON.parse(rootState.ui.sourcing.position_zone)
	// 		main_filters.position = {
	// 			lat: default_position.position.lat,
	// 			lng: default_position.position.lng
	// 		}
	// 		new_filters = true
	// 	}
	// 	if(new_filters) commit('FILTERS_UPDATE_MAIN_FILTERS', main_filters)

	// 	var filtres = new FormData();
	// 	filtres.append('filtres', JSON.stringify(state.main_filters));
	// 	dispatch('getEntreprisesAjax', {filters: filtres, offset: 0});
	// },
	// reset_q({dispatch, state}){
	// 	if(state.main_filters.q != ''){
	// 		dispatch('setMainFilter', {q:''})
	// 	}else{
	// 		// _.debounce(() => {
	// 		// 	dispatch('beforeFilterCompanies')
	// 		// }, 500)()
	// 	}
	// },
	// setMainFilter({dispatch, commit, state}, filters){
	// 	if(filters.q == undefined) filters.q = ''
	// 	let _main_filters = _.clone(state.main_filters)
	// 	let new_main_filters = {..._main_filters, ...filters}

	// 	commit('FILTERS_UPDATE_MAIN_FILTERS', new_main_filters)
	// 	dispatch('updateMainFilter')
	// },
	// _setMainDechetId({dispatch, commit, state,rootState}, main_dechet_id){
	// 	if(typeof main_dechet_id == 'undefined') main_dechet_id = state.main_filters.main_dechet_id
	// 	let new_main_filters = _.clone(state.main_filters)
	// 	new_main_filters.main_dechet_id = main_dechet_id

	// 	let dechet = false
	// 	for (var i  in rootState.ui.dechets) {
	// 		if (rootState.ui.dechets[i].data['id'] == main_dechet_id) {
	// 			dechet = rootState.ui.dechets[i].data['titre']
	// 		}
	// 	}

	// 	commit('FILTERS_UPDATE_MAIN_FILTERS', new_main_filters)
	// 	commit('FILTERS_UPDATE_MAIN_DECHET', dechet)
	// 	if(rootState.ui.page != 'search'){
	// 		dispatch('updateMainFilter')
	// 		dispatch('reset_q')
	// 	}
	// },
	// setRecherche({dispatch, commit}, recherche){
	// 	let r = JSON.parse(recherche)

	// 	commit('FILTERS_UPDATE_SECTEURS_IDS', r.secteurs_ids)
	// 	commit('FILTERS_UPDATE_DECHETS_IDS', r.dechets_ids)

	// 	commit('FILTERS_UPDATE_MAIN_FILTERS', r.main_filters)
	// 	dispatch('updateMainFilter')
	// },
	// updateDechetsIds({dispatch, commit, state, rootState}, data){
	// 	let old_nbr = state.dechets_ids.length
	// 	let new_dechets_ids = _.concat(state.dechets_ids, data.ids)
	// 	new_dechets_ids = _.uniq(new_dechets_ids)
	// 	let new_nbr = new_dechets_ids.length

	// 	if(old_nbr == new_nbr){
	// 		new_dechets_ids = _.pullAll(new_dechets_ids, data.ids)
	// 	}

	// 	commit('FILTERS_UPDATE_DECHETS_IDS', new_dechets_ids)
	// 	dispatch('reset_q')
	// },
	// reset_filters_dechets_ids({dispatch, commit, state, rootState}, data){

	// 	commit('FILTERS_UPDATE_DECHETS_IDS', [])
	// 	dispatch('reset_q')
	// },
	// updateSecteursIds({dispatch, commit, state, rootState}, ids){
	// 	let old_nbr = state.secteurs_ids.length
	// 	let new_secteurs_ids = _.concat(state.secteurs_ids, ids)
	// 	new_secteurs_ids = _.uniq(new_secteurs_ids)
	// 	let new_nbr = new_secteurs_ids.length

	// 	if(old_nbr == new_nbr){
	// 		new_secteurs_ids = _.pullAll(new_secteurs_ids, ids)
	// 	}

	// 	commit('FILTERS_UPDATE_SECTEURS_IDS', new_secteurs_ids)
	// 	dispatch('reset_q')
	// },

}

// mutations
const mutations = {
	[types.FILTERS_SET_FILTERS](state, filters) {
		let new_filters = { ...state.filters, ...filters }
		if (!_.isEqual(state.filters, new_filters)) {
			state.filters = new_filters
		}
	},


	[types.FILTERS_UPDATE_DECHETS_IDS](state, dechets_ids) {
		state.dechets_ids = dechets_ids
	},
	[types.FILTERS_UPDATE_ALL_DECHETS_IDS](state, all_dechets_ids) {
		state.all_dechets_ids = all_dechets_ids
	},
	[types.FILTERS_UPDATE_SECTEURS_IDS](state, secteurs_ids) {
		state.secteurs_ids = secteurs_ids
	},
	[types.FILTERS_LOADING](state, loading) {
		state.loading = loading
	},
	[types.FILTERS_UPDATE_MAIN_FILTERS](state, main_filters) {
		state.main_filters = main_filters
	},
	[types.FILTERS_UPDATE_MAIN_DECHET](state, main_dechet) {
		state.main_dechet = main_dechet
	},
	[types.FILTERS_SET_SORT](state, sort) {
		state.sort = sort.code
		state.sort_direction = sort.direction
	},
	[types.FILTERS_SET_PERTINANCE](state, pertinance) {
		state.pertinance = pertinance
	},
	[types.FILTERS_SET_CONTACT](state, contact) {
		state.contact = contact
	},
	[types.FILTERS_SET_CUSTOM_FILTERS](state, custom_filters) {
		if (!_.isEqual(state.custom_filters, custom_filters)) {
			state.caracteristiques = custom_filters
		}
	},
	[types.FILTERS_SET_VARIABLES_FILTERS](state, variables_filters) {
		if (!_.isEqual(state.variables_filters, variables_filters)) {
			state.variables = variables_filters
		}
	},
	[types.FILTERS_SET_ABORT_TOKEN](state, axios_source) {
		state.axios_source = axios.CancelToken.source();
	},
	[types.FILTERS_SET_ABORT_TOKEN_MAPS](state, axios_source) {
		state.axios_source_maps = axios.CancelToken.source();
	},
	[types.FILTERS_SET_ABORT_TOKEN_GRAPHS](state, axios_source) {
		state.axios_source_graphs = axios.CancelToken.source();
	},
	[types.FILTERS_SET_ABORT_TOKEN_STATS](state, axios_source) {
		state.axios_source_stats = axios.CancelToken.source();
	},
	[types.FILTERS_SET_ABORT_TOKEN_GRAPHS_ASSOLEMENT](state, axios_source) {
		state.axios_source_graphs_assolement = axios.CancelToken.source();
	},
	[types.FILTERS_SET_CARACTERISTIQUES_IDS](state, caracteristiques_ids) {
		state.caracteristiques_ids = caracteristiques_ids
	},
	[types.FILTERS_SET_VARIABLES_CODES](state, variables_codes) {
		state.variables_codes = variables_codes
	},
	[types.FILTERS_SET_CAPACITES_IDS](state, capacites_ids) {
		state.capacites_ids = capacites_ids
	},
	[types.FILTERS_SET_CAPACITES](state, capacites) {
		state.capacites = capacites
	},
	[types.FILTERS_LAST_FILTERS_TYPE](state, last_filters_type) {
		state.last_filters_type = last_filters_type
	},
	[types.FILTERS_DECHETS_QTY_MIN_MAX](state, dechets_qty_min_max) {
		state.dechets_qty_min_max = dechets_qty_min_max
	},
	[types.FILTERS_DECHET_QTY_MIN_MAX](state, dechet_qty_min_max) {
		state.dechet_qty_min_max = dechet_qty_min_max
	},
	[types.FILTERS_DECHETS_QTY_ACTIVE](state, active) {
		state.dechets_qty_active = active
	},
	[types.FILTERS_DECHET_QTY_ACTIVE](state, active) {
		state.dechet_qty_active = active
	},
	[types.FILTERS_DECHETS_QTY_SOUSDECHETS](state, sous_dechets) {
		state.dechets_qty_sousdechets = sous_dechets
	},
	[types.FILTERS_VARIABLES_TOTAUX](state, totaux_variables) {
		state.variables_totaux = totaux_variables
	},
	[types.FILTERS_CAPACITES_TOTAUX](state, totaux_capacites) {
		state.capacites_totaux = totaux_capacites
	},
	[types.FILTERS_DECHETS_QTY_SLIDER](state, dechets_qty_slider) {
		state.dechets_qty_slider = dechets_qty_slider
	},
	[types.FILTERS_DECHETS_QTY_INTERVAL](state, dechets_qty_interval) {
		state.dechets_qty_interval = dechets_qty_interval
	},
	[types.FILTERS_DECHET_QTY_SLIDER](state, dechet_qty_slider) {
		state.dechet_qty_slider = dechet_qty_slider
	},
	[types.FILTERS_DECHET_QTY_INTERVAL](state, dechet_qty_interval) {
		state.dechet_qty_interval = dechet_qty_interval
	},
	[types.FILTERS_SET_PATH_TMP](state, tmp_path) {
		state.tmp_path = tmp_path
	},
	[types.FILTERS_SET_ZONES_SELECTED_POLYGONS](state, zones) {
		state.zones_selected_polygons = zones
	},
	[types.FILTERS_SET_MARCHES_IDS](state, marches_ids) {
		state.marches_ids = marches_ids
	},
	[types.FILTERS_SET_COMPETENCES_IDS](state, competences_ids) {
		state.competences_ids = competences_ids
	},
	[types.FILTERS_SET_INEXAPI_FILTERS](state, filters) {
		console.time('FILTERS_SET_INEXAPI_FILTERS');
		state.inexapi_filters = filters
		console.timeEnd('FILTERS_SET_INEXAPI_FILTERS');
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}