import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import ui from './modules/ui'
import entreprises from './modules/entreprises'
import filters from './modules/filters'
import user from './modules/user'
import company from './modules/company'
import nomenclature from './modules/nomenclature'
import parcelle from './modules/parcelle'
import searches from './modules/searches'
import graph from './modules/graph'
import $buildFormData from './store_utils'
// import products from './modules/products'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

// const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	state:{
		'mode' : null
	},
  actions,
  modules: {
    ui,
    entreprises,
    filters,
    user,
    company,
    nomenclature,
    parcelle,
    searches,
    graph,
  },
  strict: false,
  // plugins: debug ? [createLogger()] : []
})