import Vue from 'vue';

//AS--> Prépare un tableau de données en formData
//AS--> Fournir une instance de new formData et un objet de données. parentKey sert pour la récursivité
Vue.prototype.$buildFormData = function (formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.$buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
};

export default Vue.prototype.$buildFormData;