import * as types from '../mutation-types'
import axios from '../../common/axios.js'
import _ from 'lodash'

// initial state
const state = {
	raw: [],
	companies_ids: [],
	companies_sorted: [],
	naces_ids: [],
	variables: [],
	total: 0,
	total_liste: 0,
	qty_dechets: 0,
	markers: [],
	pvr_meta: 0,
	graphs: null,
	graphs_assolement: null,
	familles_nbr: [],
	stats_familles: [],
	news: 0
}

// getters
const getters = {
	//Attention si _markers est fourni ce sont les markers qui seront utilisés et non ceux du store
	//RZ--> groupe les markers par lat et lng
	getGroupMarkers: (state, getter) => {
		let markers = state.markers
		return getter.getGroupMarkersFromMarkers(markers)
	},
	getGroupMarkersFromMarkers: (state) => (_markers) => {
		let markers = _markers ? _markers : state.markers
		let group_marker = {}
		let arrondi = 5
		_.forEach(markers, function (point) {
			let index = _.round(point.geometry.coordinates[0], arrondi) + '-' + _.round(point.geometry.coordinates[1], arrondi)

			if (group_marker[index] == undefined) {
				group_marker[index] = {
					coord: [point.geometry.coordinates[0], point.geometry.coordinates[1]],
					ids: [point._id]
				}
			} else {
				group_marker[index].ids.push(point._id)
			}
		})

		//RZ--> Enlève les markers seul
		let remove_ids = []
		_.forEach(group_marker, function (m, k) {
			if (m.ids.length > 1) {
				remove_ids = _.union(remove_ids, m.ids)
			} else {
				delete group_marker[k];
			}
		})

		return group_marker
	},
	//RZ--> Retourne le marker par id de l'entreprise
	getMarkerById: (state) => (company_id) => {
		let marker = false;
		//RZ--> On cherche le marker correspondant à l'entreprise
		_.forEach(state.markers, function (point) {
			if (point._id == company_id) {
				marker = point;
				return false
			}
		})

		return marker
	}
}

// actions
const actions = {
	getEntreprises({state}, ids){
		let entreprises = state.raw.filter(function(e){
				return ids.includes(e.id);
			} 
		)
		return entreprises
	},
	beforeFilterCompanies({rootState, commit, state, dispatch}){
		let q = rootState.filters.main_filters.q;
		//RZ--> Défini les columns à afficher et les tous déchets cochés
		let columns = []
		let all_dechets_ids = []

		if (q != '') {

		} else {
			let selected_ids = rootState.filters.dechets_ids
			let arbo = rootState.ui.dechets
			let main_dechet_id = rootState.filters.main_filters.main_dechet_id

			let recurcive = function(item, level_id){
				let ids_in = []
				if (item.level > 1) {
					if(typeof item.last_childs_ids == 'undefined') item.last_childs_ids = []
					ids_in = _.intersection(selected_ids, item.last_childs_ids)
				}
				
				//RZ--> Si le niveau de l'arbo est entierement coché
				if (ids_in.length == item.last_childs_ids.length) {
					//RZ--> On ajoute une barre et ces ids liés
					columns[level_id].levels.push({
						nom: item.data.titre,
						dechets_ids: item.all_dechets_ids,
					})
					//RZ--> On ajoute les dechets
					all_dechets_ids = _.concat(all_dechets_ids, item.all_dechets_ids)

				//RZ--> Sinon on regarde sur les enfants
				} else {
					if(typeof item.childs != 'undefined'){
						for(let i in item.childs){
							recurcive(item.childs[i], level_id)
						}
					}
				}
			}

			//RZ--> récupère la clé du niveau en cours
			let main_arbo_id = null;
			for(let i in arbo){
				if(arbo[i].data.id == main_dechet_id){
					main_arbo_id = i
					break
				}
			}

			//RZ--> Si on a un dechet principal de selectionné
			if(typeof arbo[main_arbo_id] != 'undefined' && typeof arbo[main_arbo_id]['childs'] != 'undefined'){
				//RZ--> Cherche les column dans le niveau 2
				for(let arbo_id in arbo[main_arbo_id]['childs']){
					let item = arbo[main_arbo_id]['childs'][arbo_id]

					//RZ--> Cherche si ses enfants contiennent les ids selectionnés
					let ids_in = _.intersection(selected_ids, item['last_childs_ids'])

					//RZ--> Si le niveau 2 de l'arbo est partielement coché ou coché on ajoute la column
					if(ids_in.length > 0){
						if(ids_in.length == item['last_childs_ids'].length){
							all_dechets_ids = _.concat(all_dechets_ids, item['all_dechets_ids'])
						}

						columns.push({
							id: item.data.id,
							nom: item.data.titre,
							ordre: item.data.ordre
						})
					}
				}

				//RZ--> Pour chaque column
				for(let i in columns){
					if (typeof arbo[main_arbo_id]['childs'][columns[i].id] != "undefined") {
						columns[i].levels = [];
						recurcive(arbo[main_arbo_id]['childs'][columns[i].id], i)
					}
				}
			}

			all_dechets_ids = _.uniq(all_dechets_ids)
		}

		columns = _.sortBy(columns, [function(d) { return d.ordre; }])

		commit('UI_SET_DECHETS_COLUMNS', columns)
		commit('FILTERS_UPDATE_ALL_DECHETS_IDS', all_dechets_ids)
		dispatch('filterCompanies')
	},
	filterCompanies({rootState, commit, state, dispatch}){
		// let newEntreprises = _.cloneDeep(state.raw)


		let newEntreprises = state.raw;
		let q = rootState.filters.main_filters.q;
		let companies_ids = [];
		let first_filter_companies_ids = [];

		let characteristics_min_max = [];
		let ui_filters = _.cloneDeep(rootState.ui.custom_filters)

		//RZ--> Si c'est une recherche on affecte les entreprises qui viennent du serveur
		if(q != ''){
			for(let i in newEntreprises){
				companies_ids.push(newEntreprises[i].id)
			}
		//RZ--> Sinon on filtre les entreprises en JS
		}else{
			let dechets_columns = rootState.ui.dechets_columns
			let companies_calls = rootState.user.companies_calls

			//RZ--> Récupère les filtres
			let contact = rootState.filters.contact
			let naces = rootState.filters.secteurs_ids
			let dechets_ids = rootState.filters.all_dechets_ids
			let ui_custom_filters = rootState.ui.custom_filters
			let filters_min_max = rootState.filters.characteristics_min_max
			let pertinance = rootState.filters.pertinance

			//RZ--> Défini les filtres à appliquer
			let contact_filter = contact.call || contact.no_call ? 1 : 0;
			let nace_filter = naces.length > 0 ? 1 : 0;
			let dechets_filter = dechets_ids.length > 0 ? 1 : 0;

			score_max = contact_filter + nace_filter + dechets_filter

			console.log('nace_filter', nace_filter);

			console.time('===== global filterCompanies');
			console.time('===== filterCompanies 1');
			//RZ--> Premier filtre sur les entreprises
			for (let i in newEntreprises) {
				let e = newEntreprises[i]
				let score = 0
				let filtered_dechets_ids = []
				let intersection_dechets = []
				let intersection_carac = []
				try {
					//RZ--> Si l'utilisateur a appeler l'entreprise
					e.companies_calls = companies_calls.includes(e.id) > 0
					//RZ--> Filtres les contacts
					if (contact_filter) {
						if ((contact.no_call && e.companies_calls == false) || (contact.call && e.companies_calls == true)) {
							score++
						} else {
							throw "Filter contact";
						}
					}

					//RZ--> Filtres les naces
					if (nace_filter) {
						if (naces.includes(e.nace_id)) {
							score++
						} else {
							throw "Filter nace";
						}
					}

					//RZ--> Récupération des ids de dechets de l'entreprise et des carac
					let e_dechets_ids = []
					let e_carac = {}

					_.forIn(e.dechets, function(dechet, dechet_id) {
						e_dechets_ids.push(parseInt(dechet_id))
						for(let i in dechet.carac){
							if(typeof e_carac[i] == 'undefined') e_carac[i] = {}
							e_carac[i][dechet_id] = dechet.carac[i]
						}
					});

					//RZ--> Filtres les déchets
					if (dechets_filter && typeof e.dechets != 'undefined') {
						intersection_dechets = _.intersection(e_dechets_ids, dechets_ids)
						if(intersection_dechets.length > 0){
							score++
						}else{
							throw "Filter dechets";
						}
					}

					//RZ--> Concataine les valeurs possible de caractéristiques en fonction par caractéristique
					_.forIn(e.dechets, function(dechet, dechet_id) {
						for(let i in dechet.carac){
							if(typeof characteristics_min_max[i] == 'undefined') characteristics_min_max[i] = []
							if (typeof dechet.carac[i] == 'number') {
								characteristics_min_max[i].push(dechet.carac[i])
							}
						}
					});
				}catch(error) {
					continue;
				}

				if(score != score_max){
					continue;
				} 

				// e.visible = true
				first_filter_companies_ids.push(e.id)
			}
			console.timeEnd('===== filterCompanies 1');

			
			let user_filters = _.cloneDeep(rootState.filters.custom_filters)

			//RZ--> Filtres les caractéristiques ayant un min et un max
			let characteristics_filtered = [];
			for (let j in characteristics_min_max) {
				if (typeof characteristics_min_max[j] != 'undefined' && typeof ui_filters[j] != 'undefined') {
					if (ui_filters[j].fonctionnement == 'numeric' && characteristics_min_max[j].length > 1) {
						if (characteristics_min_max[j].length > 1) {
							let min = Math.floor(_.min(characteristics_min_max[j]));
							let max = Math.ceil(_.max(characteristics_min_max[j]));

							if (min < max) {
								characteristics_filtered[j] = {min, max};
							}
						}
					}

					if (ui_filters[j].fonctionnement == 'textuel' && characteristics_min_max[j].length > 0) {
						characteristics_filtered[j] = _.uniq(characteristics_min_max[j]);
					}

				}
			}
			
			//Assigne les min-max aux filtres
			for(let i in ui_filters){
				ui_filters[i].visible = false

				if (typeof characteristics_filtered[i] != 'undefined') {
					ui_filters[i].visible = true
					if(ui_filters[i]['fonctionnement'] == 'numeric'){

						ui_filters[i].valeurs = [Math.floor(characteristics_filtered[i].min), Math.ceil(characteristics_filtered[i].max)]
						
						if(user_filters[i][0] < ui_filters[i]['valeurs'][0]){
				          user_filters[i][0] = ui_filters[i]['valeurs'][0];
				        }

				        if(user_filters[i][1] > ui_filters[i]['valeurs'][1]){
				          user_filters[i][1] = ui_filters[i]['valeurs'][1];
				        }
					}else if(ui_filters[i]['fonctionnement'] == 'textuel'){
						ui_filters[i].valeurs = characteristics_filtered[i]
					}
				}
			}

			//Vérifi si on doit filtrer sur les caractéristiques
			let filters = []
			for(let i in ui_filters){
				if (ui_filters[i].visible) {
					if(ui_filters[i]['fonctionnement'] == 'textuel' && user_filters[i].length > 0){
						filters.push({id: i, type: ui_filters[i]['fonctionnement'], values: user_filters[i]})
					} 
					if(ui_filters[i]['fonctionnement'] == 'numeric'){
						if(ui_filters[i].valeurs[0] != user_filters[i][0] || ui_filters[i].valeurs[1] != user_filters[i][1]){
							filters.push({id: i, type: ui_filters[i]['fonctionnement'], values: user_filters[i]})
						} 
					} 
				}
			}

			let characteristics_filter = filters.length > 0 ? 1 : 0;
			let pertinance_filter = pertinance != 0 && dechets_columns.length > 0 ? 1 : 0;
			
			console.log('characteristics_filter', characteristics_filter, characteristics_min_max, characteristics_filtered, ui_filters);

			let score_max = characteristics_filter + pertinance_filter

			console.time('===== filterCompanies 2');
			//RZ--> Deuxième filtre sur les entreprises et ajout de data
			newEntreprises = newEntreprises.map(function(e){
				let score = 0
				try {
					if (!first_filter_companies_ids.includes(e.id)) {
						throw "Non valide";
					}

					e.companies_calls = companies_calls.includes(e.id) > 0

					let filtered_dechets_ids = []
					let intersection_dechets = []
					let intersection_carac = []

					let e_dechets_ids = []
					let e_carac = {}

					_.forIn(e.dechets, function(dechet, dechet_id) {
						e_dechets_ids.push(parseInt(dechet_id))
						for(let i in dechet.carac){
							if(typeof e_carac[i] == 'undefined') e_carac[i] = {}
							e_carac[i][dechet_id] = dechet.carac[i]
						}
					});


					//RZ--> Filtres les déchets
					if (dechets_filter && typeof e.dechets != 'undefined') {
						intersection_dechets = _.intersection(e_dechets_ids, dechets_ids)
					}
					
					//RZ--> Filtres les caractéristiques
					if (characteristics_filter) {
						for(let i in filters){
							let filter_id = filters[i]['id']
							if(typeof e_carac[filter_id] != 'undefined'){
								let carac = e_carac[filter_id]

								let having = false
								for(let j in carac){
									switch(filters[i]['type']){
										case 'numeric':
											if(carac[j] >= filters[i].values[0] && carac[j] <= filters[i].values[1]){
												having = true
												intersection_carac.push(parseInt(j))
											}
											break
										case 'textuel':
											if(filters[i].values.includes(carac[j])){
												having = true
												intersection_carac.push(parseInt(j))
											}
										break
									}
								}

								if (having) {
									score++
								} else {
									throw "Filter characteristics";
								}
							}else{
								throw "Filter characteristics";
							}
						}
					}

					if(intersection_dechets.length > 0 && intersection_carac.length > 0){
						filtered_dechets_ids = _.intersection(intersection_dechets, intersection_carac)
					}else{
						if(intersection_dechets.length > 0) filtered_dechets_ids = intersection_dechets
						if(intersection_carac.length > 0) filtered_dechets_ids = intersection_carac
					}

					//RZ--> IDS des déchets filtrés
					if(dechets_filter || characteristics_filter){
						e.filtered_dechets_ids = _.uniq(filtered_dechets_ids)
					}

					

					let ips = {
						nbr: 0,
						total: 0
					}

					let levels = {}

					//RZ--> Calcul des barres en fonction des niveaux selectionnés
					for (let i in dechets_columns) {
						let col_id = dechets_columns[i].id
						levels[col_id] = {
							levels: [],
							total: 0,
							nbr: 0
						}

						for (let j in dechets_columns[i].levels) {
							let level = {}

							level.nom   = dechets_columns[i].levels[j].nom
							level.total = 0
							level.nbr   = 0
							let ids_in = _.intersection(e.filtered_dechets_ids, dechets_columns[i].levels[j].dechets_ids)
							for(let k in ids_in){
								let d_key = _.findKey(e.dechets, { 'id': ids_in[k]});
								let d = e.dechets[d_key]

								level.total += parseFloat(d.qty)
								level.nbr++
								ips.nbr++
								ips.total += d.ip
							}

							levels[col_id].total += level.total
							levels[col_id].nbr   += ids_in.length;
							if(level.nbr == 0){
								levels[col_id].levels.push(null)
							}else{
								levels[col_id].levels.push(level)
							}
						}

						let total = 0

						let bg = [
							'#009AFF',
							'linear-gradient(180deg, #00CEA8 0%, #07CE6F 100%)',
							'#ff8100',
							'#6B61D2',
							'linear-gradient(270deg, #07CE6F 0%, #00CEA8 100%)',
							'#F55046',
							'#bd66e0',
							'linear-gradient(90deg, #FE6370 0%, #FE8C6F 100%)',
							'#007ED1',
							'#D86D00',
							'#9F97F4',
							'#CD3329',
							'#E39EFF',
							'#0065A7',
							'#B35A00',
							'#392FA7',
							'#A4221A',
							'#8836A9',
							'#29AAFF',
							'#FF9327',
							'#FF9B95',
							'#731997',
							'#53BBFF',
							'#CFD8EC'
						]
						let z = 0
						for(let i in levels[col_id].levels){
							if(levels[col_id].levels[i]){
								// levels[col_id].levels[i].is_first = total == 0 && levels[col_id].levels[i].total > 0
								// total += levels[col_id].levels[i].total
								// levels[col_id].levels[i].is_last = total >= levels[col_id].total && levels[col_id].levels[i].total > 0
								levels[col_id].levels[i].bg = bg[z]
							}

							if (bg.length == z-1) {
								z = 0;
							} else {
								z++;
							}
						}
					}

					e.levels = levels


					//RZ--> Calcul de l'IP
					e.ip = ips.nbr > 0 ? Math.round(ips.total / ips.nbr) : 0

					if (pertinance_filter) {
						if (e.ip >= pertinance) {
							score++
						} else {
							throw "Filter pertinance";
						}
					}



				}catch(error) {
					return e
				}

				if(score != score_max){
					return e
				} 

				companies_ids.push(e.id)
				return e
			});

			console.timeEnd('===== filterCompanies 2 ');
			console.timeEnd('===== global filterCompanies');
		}


		// commit('FILTERS_SET_CUSTOM_FILTERS', user_filters)
		// commit('UI_SET_UI_CUSTOM_FILTRERS', ui_filters)
		// commit('SET_COMPANIES_IDS', companies_ids)
		// commit('SET_ENTREPRISES', newEntreprises);
		dispatch('sortCompanies');
		
		// newEntreprises = _.compact(newEntreprises)	
		// dispatch('sortCompanies', newEntreprises)
	},
	sortCompanies({rootState, commit, state}){
		console.time('sortCompanies');

		let companies_sorted = state.raw;
		let companies_ids = state.companies_ids;

		//RZ--> Code de tri
		let sort_code = rootState.filters.sort
		let sort_direction = rootState.filters.sort_direction

		companies_sorted = companies_sorted.filter(e => companies_ids.includes(e.id));

		//RZ--> tri des entreprises
		companies_sorted = _.sortBy(companies_sorted, function(e){
		  if (sort_code == 'companies') {
		    return e.nom
		  }

		  if (sort_code == 'distance') {
		    return e.dist
		  }

		  if (sort_code == 'contact') {
		    return e.companies_calls
		  }

		  if (sort_code == 'ip') {
		    return e.ip
		  }

		  //RZ--> Si c'est un id c'est qu'on trie une colonne de dechet
		  if (_.isInteger(sort_code)) {
		    if (typeof e.levels[sort_code] != 'undefined') {
		      return e.levels[sort_code].total
		    }
		  }
		})

		//RZ--> Si direction de tri vaux DESC on inverse l'array
		if(sort_direction == 'DESC') companies_sorted = _.reverse(companies_sorted)
		console.timeEnd('sortCompanies');

		commit('SET_COMPANIES_SORTED', companies_sorted);
	},
	updateFieldCompany({state, commit, rootState}, {identifiant, field, value}){
		
		//AS--> Modifie le statut dans la liste des entreprises
		let entreprises = _.cloneDeep(state.raw)
		let company = _.find(entreprises, { 'identifiant': identifiant})
		company[field] = value
		commit('SET_ENTREPRISES', entreprises)

		//AS--> Modifie le statut dans la base de données iNex
		let values = {}
		values[field] = value
		axios.put('api=inex/companies/'+identifiant, values)
	}
}

// mutations
const mutations = {
	[types.ENTREPRISES_INIT] (state, entreprises) {
		console.time('ENTREPRISES_INIT');
		state.raw       = entreprises
		console.timeEnd('ENTREPRISES_INIT');
	},
	[types.NACES_IDS] (state, naces_ids) {
		console.time('NACES_IDS');
		//AS--> Supprime les valeurs null
		state.naces_ids = _.values(_.omitBy(naces_ids, _.isNil));
		console.timeEnd('NACES_IDS');
	},
	[types.VARIABLES] (state, variables) {
		console.time('VARIABLES');
		state.variables = variables;
		console.timeEnd('VARIABLES');
	},
	[types.SET_MARKERS] (state, markers) {
		console.time('SET_MARKERS');
		state.markers = markers;
		console.timeEnd('SET_MARKERS');
	},
	[types.SET_MODE_CALCUL] (state, mode_calcul) {
		console.time('SET_MODE_CALCUL');
		state.mode_calcul = mode_calcul;
		console.timeEnd('SET_MODE_CALCUL');
	},
	[types.SET_MAP_GROUPED] (state, map_grouped) {
		console.time('SET_MAP_GROUPED');
		state.map_grouped = map_grouped;
		console.timeEnd('SET_MAP_GROUPED');
	},
	[types.SET_ENTREPRISES] (state, entreprises) {
		console.time('SET_ENTREPRISES');
		state.raw = entreprises
		console.timeEnd('SET_ENTREPRISES');
	},
	[types.SET_COMPANIES_IDS] (state, companies_ids) {
		console.time('SET_COMPANIES_IDS');
		state.companies_ids = companies_ids
		console.timeEnd('SET_COMPANIES_IDS');
	},
	[types.SET_COMPANIES_SORTED] (state, entreprises) {
		console.time('SET_COMPANIES_SORTED');
		state.companies_sorted = entreprises
		console.timeEnd('SET_COMPANIES_SORTED');
	},
	[types.ENTREPRISES_TOTAL] (state, total) {
		console.time('ENTREPRISES_TOTAL');
		state.total = total
		console.timeEnd('ENTREPRISES_TOTAL');
	},
	[types.ENTREPRISES_TOTAL_LISTE] (state, total) {
		console.time('ENTREPRISES_TOTAL_LISTE');
		state.total_liste = total
		console.timeEnd('ENTREPRISES_TOTAL_LISTE');
	},
	[types.ENTREPRISES_QUANTITE] (state, qty_dechets) {
		console.time('ENTREPRISES_QUANTITE');
		state.qty_dechets = qty_dechets
		console.timeEnd('ENTREPRISES_QUANTITE');
	},
	[types.ENTREPRISES_GRAPHS] (state, graphs) {
		console.time('ENTREPRISES_GRAPHS');
		state.graphs = graphs
		console.timeEnd('ENTREPRISES_GRAPHS');
	},
	[types.ENTREPRISES_GRAPHS_ASSOLEMENT] (state, graphs) {
		console.time('ENTREPRISES_GRAPHS_ASSOLEMENT');
		state.graphs_assolement = graphs
		console.timeEnd('ENTREPRISES_GRAPHS_ASSOLEMENT');
	},
	[types.ENTREPRISES_FAMILLES_NBR] (state, familles_nbr) {
		console.time('ENTREPRISES_FAMILLES_NBR');
		state.familles_nbr = familles_nbr
		console.timeEnd('ENTREPRISES_FAMILLES_NBR');
	},
	[types.ENTREPRISES_STATS_FAMILLES] (state, stats_familles) {
		console.time('ENTREPRISES_STATS_FAMILLES');
		state.stats_familles = stats_familles
		console.timeEnd('ENTREPRISES_STATS_FAMILLES');
	},
	[types.ENTREPRISES_NEWS] (state, news) {
		console.time('ENTREPRISES_NEWS');
		state.news = news
		console.timeEnd('ENTREPRISES_NEWS');
	},
	[types.ENTREPRISES_PVR_META] (state, pvr_meta) {
		console.time('ENTREPRISES_PVR_META');
		state.pvr_meta = pvr_meta
		console.timeEnd('ENTREPRISES_PVR_META');
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}