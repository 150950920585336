import axios from 'axios'
import store from '../store/store'
import CryptoJS from 'crypto-js'
import _ from 'lodash'

axios.defaults.headers = {
	'X-Requested-With': 'XMLHttpRequest',
	// 'Content-Type': 'multipart/form-data'
};

// axios.defaults.baseURL = 'http://grandest.inex.arnaud-santini.dev.oliv.fr/';

const instance = axios.create({});

const urlParams = new URLSearchParams(window.location.search);
const noencoding = urlParams.has('noencoding')

const sleepRequest = (milliseconds, originalRequest) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(instance(originalRequest)), milliseconds);
    });
};

axios.interceptors.request.use(function (config) {

	let original_url = _.clone(config.url)

	//AS--> Transformation des url pour appeler iNex
	if(config.url.substring(0, 23) == '/sourcing_api/index.php'){
		config.url = config.url.replace('/sourcing_api/index.php', 'api=inex/companies/index/{view_code}')
	}else if(config.url.substring(0, 21) == '/sourcing_api/map.php'){
		config.url = config.url.replace('/sourcing_api/map.php', 'api=inex/companies/map/{view_code}')
	}

	if(original_url != config.url){
		console.log('********************************');
		console.log(original_url);
		console.log(config.url);
		console.log('********************************');
	}

	//AS--> Ajoute les identifiants d'utilisateur et vue
	let email       = store.state.user.user.email
	let code_vue    = store.state.ui.sourcing.url_sourcing
	let parcelle_id = store.state.parcelle.parcelle_on_id
	let lang        = store.state.ui.langue

	//AS--> Si c'est pour joindre l'API iNex
    if(config.url.substring(0, 8) == 'api=inex' || config.url.substring(0, 42) == 'https://api.dev.inex-circular.com/calques/' || config.url.substring(0, 38) == 'https://api.inex-circular.com/calques/'){

    	//AS--> Met le bon domaine
    	config.url = config.url.replace('api=inex', store.state.ui.env == 'PROD' ? 'https://api.inex-circular.com' : 'https://api.dev.inex-circular.com')

		//AS--> Ajoute les identifiants de connexion dans les headers
		config.headers['api_key']                                          = 'c0db332d-9c85-4e77-8582-86b3b85864c9'
		config.headers['user-name']                                        = 'arnaud'
		if(store.getters.isRefashion){
			config.headers['api_key']                                          = 'a4639145-4f7b-4d9a-a677-b518a0f5ed31'
			config.headers['user-name']                                        = 'refashion'
		}

		if(!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json'
		if(!config.headers['Accept']) config.headers['Accept']             = 'application/json'
		config.withCredentials                                             = true
    	// if(typeof config.data == 'undefined') config.data = {data:{}} //AS--> Maintient un data, même à vide, sinon 'Content-Type' est remis à vide

		// if(config.url == 'https://api.dev.inex-circular.com/contacts/'){
		// 	config.headers['api_key']      = 'b97e8a8f-87b3-4244-919a-d55a6c3575a6';
		// 	config.headers['user-name']    = 'inex_web';
		// }

    }

	//AS--> Si c'est pour joindre l'API iNex interne
    if(config.url.substring(0, 11) == 'api=webinex'){

    	//AS--> Met le bon domaine
    	config.url = config.url.replace('api=webinex', '/plugin_projet/plugin_projet_inexapi/dispatch')
    	if(config.data && !config.data.data) config.data = {data:config.data}

    }
	
	//AS--> ####################### DEV ######################################
	//AS--> Pour tester une fiche Entreprise Json
	// if(config.url.indexOf('is_for_front=true') != -1) config.url = "/sourcing_api/entreprise.php"
	//AS--> ####################### DEV ######################################
	
	config.url = config.url.replace('{user_email}', email)
	config.url = config.url.replace('{view_code}', code_vue)
	config.url = config.url.replace('{parcelle_id}', parcelle_id)

	//AS--> Si config.url contient "inex-circular.com", on ajoute les identifiants
	if(config.url.indexOf('inex-circular.com') != -1 || config.url.substring(0, 1) == '/'){
		let identifiant = 'user_id='+email+'&view_code='+code_vue+'&lang='+lang
		config.url = config.url.indexOf('?') == -1 ? config.url+'?'+identifiant : config.url+'&'+identifiant;
	}

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

axios.interceptors.response.use(function (response) {

	if(response.data == 'CONNEXION'){
		store.commit('UI_SET_MODAL', {modal: 'connection'});
		commit('UI_SET_LOADING', false)
	}else{
		if (response.headers['content-type'] == 'application/text') {
			
			var obj_json  = response.data;
			var encrypted = obj_json.ciphertext;
			var salt      = CryptoJS.enc.Hex.parse(obj_json.salt);
			var iv        = CryptoJS.enc.Hex.parse(obj_json.iv);

			//AS--> Décode les données qui reviennent de l'API iNex
			if (response.config.url.includes('.inex-circular.com')) {

				var key       = CryptoJS.PBKDF2(store.state.ui.a, salt, { hasher: CryptoJS.algo.SHA512, keySize: 4, iterations: 1500});
				var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv, mode: CryptoJS.mode.CBC});
			

			//AS--> Décode les données qui reviennent du site
			} else {

				var key       = CryptoJS.PBKDF2(store.state.ui.b, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});
				var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

			}
			
			response.data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

		}
		// console.log(response.data);

		if(noencoding) console.log(response.data)
	}
	// Do something with response data
	return response;

}, function (error) {

	//AS--> Si c'est un avortement, on ne fait rien de plus
	if(error.__CANCEL__ || (typeof error.message != 'undefined' && error.message == 'Cancel')) return Promise.reject(error);

	console.log('==== response API Error ');
	console.log(error);
	console.log(error.config);
	console.log(error.response);

	//AS--> Décode les données qui reviennent de l'API iNex
	if (error.config.url.includes('.inex-circular.com')) {
		console.log('=> API Inex');

		//AS--> Si iNex répond par une 401, on déconnecte l'utilisateur
		if(error.response.status == 401 && store.state.ui.modal != 'connection'){
			console.log('=> Réponse 401 : on déconnecte !!');
			store.dispatch('user_deconnection')
		}
	}

	//AS--> Si le paramètre de requête "retry" existe et vaut true (nombre de milisecondes avant nouvelle tentative, on retente une fois la requête
	if(typeof error.config.retry != 'undefined' && error.config.retry){
		if(error.config.data) error.config.data = JSON.parse(error.config.data)
		const { config, response: { status }} = error;
		const originalRequest = config;
		return sleepRequest(error.config.retry, originalRequest);

	//AS--> Sinon, on renvoie l'erreur
	}else{
		return Promise.reject(error);
		
	}

});

export default axios