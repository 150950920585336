import * as types from '../mutation-types'
import _ from 'lodash'

// initial state
const state = {
	couleurs_dechets: {},
	couleurs_dechets_details: {},
	colors: [
		'#07CE6F',
		'#3861FB',
		'#047B42',
		'#FF8642',
		'#717579',
		'#BF3081',
		'#0E1C36',
		'#07C2CE',
		'#3A07CE',
		'#7607CE',
		'#CE5A07',
		'#286347',
		'#BECE07',
		'#76ECB4',
		'#FCB086',
		'#2055B9',
		'#32DA7F',
		'#ED65B2',
		'#F45B5B',
		'#2FA399',
	]
}

// getters
const getters = {
	getGraphCouleursDechets: (state, getters, rootState) => (code, key) => {
		return state[code][key]
	}
}

// actions
const actions = {
	affecteGraphCouleursDechets({ state, commit }, params) {
		let code = params.code 	//AS--> Code de nomenclature (couleurs_dechets, couleurs_dechets_details, ...)
		let data = params.data 	//AS--> Array d'objets de données à compléter
		let key  = params.key 	//AS--> Clé d'identification pour affectation de la couleur

		let couleurs = _.cloneDeep(state[code])

		_.forEach(data, function(item, index){

			//AS--> Si une couleur a déjà été affectée à cette clé, on retourne cette couleur
			if(couleurs[item[key]] != undefined){
				item.color = couleurs[item[key]]

			//AS--> Cherche la première couleur qui n'a pas encore été affectée et l'affecte à cette key
			}else{
				let couleur = _.find(state.colors, function(c){
					return !_.includes(couleurs, c)
				})
				couleurs[item[key]] = couleur
				item.color = couleur
			}
		})

		commit(types['GRAPH_' + code.toUpperCase()], couleurs)
	}
}

// mutations
const mutations = {
	[types.GRAPH_COULEURS_DECHETS](state, couleurs_dechets) {
		console.time('GRAPH_COULEURS_DECHETS');
		state.couleurs_dechets = couleurs_dechets;
		console.timeEnd('GRAPH_COULEURS_DECHETS');
	},
	[types.GRAPH_COULEURS_DECHETS_DETAILS](state, couleurs_dechets_details) {
		console.time('GRAPH_COULEURS_DECHETS_DETAILS');
		state.couleurs_dechets_details = couleurs_dechets_details;
		console.timeEnd('GRAPH_COULEURS_DECHETS_DETAILS');
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}